import { LIMIT_IN_PAGE_USERS } from 'modules/users/store/constants';
import { EDIT_FORM_SUCCESS } from '../../../components/CommonForm/store/constants';
import {
    GET_WORKERS_SUCCESS,
    GET_WORKER_SUCCESS,
    DELETE_WORKER_SUCCESS,
    PROCESSING,
    CLEAR_DATA_WORKER,
    SET_FILTER,
    SET_VERIFY_WORKER,
    GENDER_TYPE,
} from './constants';

const initState = {
    workers: [],
    worker: {},
    processing: false,
    filterWorker: {
        page: 1,
        limit: LIMIT_IN_PAGE_USERS,
        from: '',
        to: '',
    },
    total: 0,
};

const AppMenu = (state = initState, action) => {
    switch (action.type) {
        case PROCESSING: {
            return {
                ...state,
                processing: true,
            };
        }
        case GET_WORKERS_SUCCESS: {
            const {
                data,
                meta: { total },
            } = action.payload;
            return {
                ...state,
                workers: data,
                total,
                processing: false,
            };
        }
        case DELETE_WORKER_SUCCESS: {
            const newWorkers = [...state.workers].filter((worker) => worker._id !== action.payload);
            return {
                ...state,
                workers: newWorkers,
            };
        }

        case GET_WORKER_SUCCESS: {
            return {
                ...state,
                worker: {
                    ...action.payload,
                    gender: GENDER_TYPE[0].value,
                },
                processing: false,
            };
        }

        case CLEAR_DATA_WORKER: {
            return {
                ...state,
                worker: {},
            };
        }

        case SET_FILTER: {
            return {
                ...state,
                filterWorker: action.payload,
            };
        }

        case EDIT_FORM_SUCCESS: {
            const { id, values } = action.payload;
            const { name, email, role } = values;
            const newWorkers = [...state.workers].map((worker) => {
                if (worker._id === id) {
                    return {
                        ...worker,
                        _id: id,
                        username: name,
                        email,
                        role,
                    };
                }
                return worker;
            });
            return {
                ...state,
                workers: newWorkers,
            };
        }

        case SET_VERIFY_WORKER: {
            return {
                ...state,
                worker: {
                    ...state.worker,
                    is_verified: action.payload,
                },
            };
        }

        default:
            return state;
    }
};

export default AppMenu;
