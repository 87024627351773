import Api from './api';
import { queryFormat } from 'ultils/common';
import axiosClient from './axiosClient';

const URL_CATEGORY = 'provinces';

const ProvinceAPI = {
    getProvinces: (data, token) => {
        const url = URL_CATEGORY;
        return axiosClient.get(url, token);
    },
    getListProvince: (params) => {
        const url = `${URL_CATEGORY}${queryFormat(params || {})}`;
        return Api.get(url);
    },
};

export default ProvinceAPI;
