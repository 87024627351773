export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILED = 'GET_CATEGORIES_FAILED';

export const GET_PROVINCES_REQUEST = 'GET_PROVINCES_REQUEST';
export const GET_PROVINCES_SUCCESS = 'GET_PROVINCES_SUCCESS';
export const GET_PROVINCES_FAILED = 'GET_PROVINCES_FAILED';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const CLEAR_DATA_USER = 'CLEAR_DATA_USER';
export const SET_FILTER = 'SET_FILTER';
export const SET_FILTER_USER_TABLE = 'SET_FILTER_USER_TABLE';
export const SET_DATA_USERS_CHANGE = 'SET_DATA_USERS_CHANGE';

export const PROCESSING = 'PROCESSING';

export const LIMIT_IN_PAGE_USERS = 12;

export const DEFAULT_MODAL = {
    OPEN: true,
    CLOSE: false,
    HAS_FORM: true,
    NO_FORM: false,
};

export const USER_ROLES = ['user', 'admin'];

export const INIT_VALUE_FORM_EDIT_USER = {
    name: '',
    address: '',
    phone_number: '',
    birthday: '',
    gender: '',
    year_experience: '',
    reliability: '',
};

export const FIELDS_EDIT_USER = {
    NAME: 'name',
    ADDRESS: 'address',
    PHONE_NUMBER: 'phone_number',
    BIRTHDAY: 'birthday',
    GENDER: 'gender',
    YEAR_EXPERIENCE: 'year_experience',
    RELIABILITY: 'reliability',
};

export const GENDER_TYPE = [
    { id: 0, value: 0, name: 'MALE' },
    { id: 1, value: 1, name: 'FEMALE' },
    { id: 2, value: 2, name: 'OTHER' },
];
