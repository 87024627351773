import React from 'react';
import { Link } from 'react-router-dom';
import './PageNotFound.scss';
const PageNotFound = () => {
    return (
        <>
            <h1>404 Error Page</h1>
            <section className="error-container">
                <span className="four">
                    <span className="screen-reader-text">4</span>
                </span>
                <span className="zero">
                    <span className="screen-reader-text">0</span>
                </span>
                <span className="four">
                    <span className="screen-reader-text">4</span>
                </span>
            </section>
            <div className="link-container">
                <Link className="more-link" to="/">
                    Trở về lại trang chủ
                </Link>
            </div>
        </>
    );
};

export default PageNotFound;
