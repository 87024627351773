import {
    ADD_NEW_NOTIFICATION,
    ADD_NEW_NOTIFICATION_FAILED,
    CLEAR_NOTIFICATION,
    DELETE_NOTIFICATION,
    DELETE_NOTIFICATION_FAILED,
    DELETE_NOTIFICATION_SUCCESS,
    EDIT_NOTIFICATION,
    EDIT_NOTIFICATION_FAILED,
    GET_NOTIFICATION,
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_FAILED,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATION_FAILED,
    GET_NOTIFICATION_SUCCESS,
    PROCESSING,
    SET_FILTER_NOTIFICATION,
    SET_IS_EDIT_NOTIFICATION,
    SET_NOTIFICATIONS_DEFAULT,
} from './constants';

export const processing = () => ({
    type: PROCESSING,
});

export const getNotifications = (payload) => ({
    type: GET_NOTIFICATIONS,
    payload,
});

export const getNotificationsSuccess = (payload) => ({
    type: GET_NOTIFICATIONS_SUCCESS,
    payload,
});

export const getNotificationsFailed = (payload) => ({
    type: GET_NOTIFICATIONS_FAILED,
    payload,
});

export const setNotificationsDefault = () => ({
    type: SET_NOTIFICATIONS_DEFAULT,
});

export const getNotification = (payload) => ({
    type: GET_NOTIFICATION,
    payload,
});

export const getNotificationSuccess = (payload) => ({
    type: GET_NOTIFICATION_SUCCESS,
    payload,
});

export const getNotificationFailed = (payload) => ({
    type: GET_NOTIFICATION_FAILED,
    payload,
});

export const clearNotification = () => ({
    type: CLEAR_NOTIFICATION,
});

export const addNewNotification = (payload) => ({
    type: ADD_NEW_NOTIFICATION,
    payload,
});

export const addNewNotificationFailed = (payload) => ({
    type: ADD_NEW_NOTIFICATION_FAILED,
    payload,
});

export const editNotification = (payload) => ({
    type: EDIT_NOTIFICATION,
    payload,
});

export const editNotificationFailed = (payload) => ({
    type: EDIT_NOTIFICATION_FAILED,
    payload,
});

export const setNotificationFilter = (payload) => ({
    type: SET_FILTER_NOTIFICATION,
    payload,
});

export const setEditNotification = (payload) => ({
    type: SET_IS_EDIT_NOTIFICATION,
    payload,
});

export const deleteNotification = (payload) => ({
    type: DELETE_NOTIFICATION,
    payload,
});

export const deleteNotificationFailed = (payload) => ({
    type: DELETE_NOTIFICATION_FAILED,
    payload,
});

export const deleteNotificationSuccess = (payload) => ({
    type: DELETE_NOTIFICATION_SUCCESS,
    payload,
});
