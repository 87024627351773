import { HIDE_CONFIRM, SHOW_CONFIRM } from "./constants";

export const initialState = {
    show: false,
    text: '',
    confirmationCallback: null,
};

const Dialog = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_CONFIRM:
            return {
                ...state,
                show: true,
                text: action.payload.text,
                confirmationCallback: action.payload.confirmationCallback
            };
        case HIDE_CONFIRM:
            return initialState;
        default:
            return initialState;
    }
};
export default Dialog;