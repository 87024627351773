import axiosClient from './axiosClient';

const NotificationAPI = {
    getNotifications: (data, token) => {
        const url = `admin-notifications${data}`;
        return axiosClient.get(url, token);
    },
    postCreateNewNotification: (data, token) => {
        const url = `admin-notification`;
        return axiosClient.post(url, data, token);
    },
    patchUpdateNotification: (idNotification, data, token) => {
        const url = `admin-notification/${idNotification}`;
        return axiosClient.patch(url, data, token);
    },
    getNotification: (idNotification, token) => {
        const url = `admin-notification/${idNotification}`;
        return axiosClient.get(url, token);
    },
    deleteNotification: (idNotification, token) => {
        const url = `admin-notification/${idNotification}`;
        return axiosClient.delete(url, token);
    },
};

export default NotificationAPI;
