import axiosClient from './axiosClient';

const IssuesAPI = {
    getIssues: (data, token) => {
        const url = `issues${data}`;
        return axiosClient.get(url, token);
    },
    postCreateNewIssue: (data) => {
        const url = `issues`;
        return axiosClient.post(url, data);
    },
    getIssue: (data, token) => {
        const url = `issues/${data}`;
        return axiosClient.get(url, token);
    },
};

export default IssuesAPI;
