import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BaseButton from 'components/BaseButton/index';
import { useRef } from 'react';

const ConfirmationDialog = ({ open, text, confirmAction, cancelAction }) => {
    const { t } = useTranslation();
    const confirmationDialogRef = useRef();

    const handleClickOutside = (e) => {
        if (!confirmationDialogRef.current.contains(e.target)) {
            cancelAction && cancelAction();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    return (
        <>
            {open ? (
                <div className="portal-overlay">
                    <div className="confirm-dialog" ref={confirmationDialogRef} onClick={handleClickOutside}>
                        <p>{text && text}</p>
                        <div className="confirm-dialog__footer">
                            <BaseButton className="btn btn-warning mr-3" onClick={confirmAction}>
                                {t('common.agree')}
                            </BaseButton>
                            <BaseButton className="btn btn-primary" onClick={cancelAction}>
                                {t('common.cancel')}
                            </BaseButton>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default ConfirmationDialog;
