import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { setCloseModal } from '../BaseModal/store/actions';

const FormActions = ({ onSubmit = () => {} }) => {
    const dispatch = useDispatch();
    const handleCancel = () => {
        dispatch(setCloseModal());
    };

    const handleSubmit = () => {
        onSubmit();
    };

    return (
        <div className="b-form-actions text-right mr-2 mb-2">
            <Button className="mr-2" onClick={handleCancel}>
                Cancel
            </Button>
            <Button className="px-3" type="submit" color="primary" onClick={handleSubmit}>
                Submit
            </Button>
        </div>
    );
};

export default FormActions;
