import {
    CLEAR_ISSUE,
    GET_ISSUE,
    GET_ISSUES,
    GET_ISSUES_FAILED,
    GET_ISSUES_SUCCESS,
    GET_ISSUE_FAILED,
    GET_ISSUE_SUCCESS,
    PROCESSING,
    SET_ISSUES_DEFAULT,
} from './constants';

export const processing = () => ({
    type: PROCESSING,
});

export const getIssues = (payload) => ({
    type: GET_ISSUES,
    payload,
});

export const getIssuesSuccess = (payload) => ({
    type: GET_ISSUES_SUCCESS,
    payload,
});

export const getIssuesFailed = (payload) => ({
    type: GET_ISSUES_FAILED,
    payload,
});

export const setIssuesDefault = () => ({
    type: SET_ISSUES_DEFAULT,
});

export const getIssue = (payload) => ({
    type: GET_ISSUE,
    payload,
});

export const getIssueSuccess = (payload) => ({
    type: GET_ISSUE_SUCCESS,
    payload,
});

export const getIssueFailed = (payload) => ({
    type: GET_ISSUE_FAILED,
    payload,
});

export const clearIssue = () => ({
    type: CLEAR_ISSUE,
});
