const Vi = {
    translation: {
        setting_languages: {
            en: 'Tiếng Anh',
            vi: 'Tiếng Việt',
            change_language: 'Đổi ngôn ngữ',
        },
        issues_page: {
            issues: 'Vấn đề',
            list: 'Danh sách',
            search_placeholder: 'Tìm kiếm',
            type_placeholder: 'Chọn trạng thái',
            type_payment_placeholder: 'Chọn thanh toán',
            type: 'Trạng thái',
            type_payment: 'Thanh toán',
            type_issues: {
                all: 'Tất cả',
                done: 'Hoàn tất',
                cancelled: 'Đã hủy',
                open: 'Mở',
                waiting_verify: 'Đang chờ xác minh',
                system_verifying: 'Hệ thống xác minh ',
                chatting: 'Tán gẫu',
                inprogress: 'Trong tiến trình',
                system: 'Hệ thống',
            },
            information: 'Thông tin',
            location: 'Địa chỉ',
            status: 'Trạng thái',
            payment_method: 'Phương thức thanh toán',
            date: 'Ngày tháng',
            requestUser: 'Yêu cầu người dùng',
            attachedFiles: 'File đính kèm',
            creator: 'Người tạo',
            name: 'Tên',
            gender: 'Giới tính',
            sessionRole: 'Vai trò tạm thời',
            role: 'Vai trò',
            address: 'Địa chỉ',
            phone: 'Số điện thoại',
            birthday: 'Ngày sinh',
            contactInfo: 'Thông tin liên hệ',
            nothingData: 'Không tìm thấy vấn đề nào hết !',
            total: 'Tổng bill',
            discount: 'Số tiền khuyến mãi',
            title: 'Tiêu đề',
            totalWorkerReceive: 'Tổng tiền thợ nhận',
            totalIntoWalletWorker: 'Số dư vào ví',
            totalCustomerPaid: 'Tổng tiền khách trả',
            fee: 'Tiền phí',
            cost: 'Công Thợ',
            surveyFee: 'Phí khảo sát',
            distanceFee: 'Phí khoảng cách',
            totalBill: 'Tổng bill',
            materialCost: 'Giá vật tư',
        },
        events_page: {
            events: 'Sự kiện',
            search_placeholder: 'Tìm kiếm',
            type_placeholder: 'Thể loại',
            type: 'Type',
            type_start_date: 'Ngày bắt đầu',
            type_end_date: 'Ngày kết thúc',
            type_status: 'Trạng thái',
            startDate: 'Ngày bắt đầu',
            endDate: 'Ngày kết thúc',
            type_events: {
                title: 'Loại hình KM',
                all: 'Tất cả',
                voucher: 'Voucher',
                bonus: 'Bonus',
                discount: 'Discount',
                special: 'Special',
                lw100: 'Thấp hơn 100%',
                lw90: 'Thấp hơn 90%',
                lw80: 'Thấp hơn 80%',
                lw70: 'Thấp hơn 70%',
                lw60: 'Thấp hơn 60%',
                lw50: 'Thấp hơn 50%',
                lw40: 'Thấp hơn 40%',
                lw30: 'Thấp hơn 30%',
                lw20: 'Thấp hơn 20%',
                lw10: 'Thấp hơn 10%',
            },
            status: {
                title: 'Trạng thái',
                all: 'Tất cả',
                active: 'Hiệu lực',
                inActive: 'Không hiệu lực',
            },
            nothingData: 'Không tìm thấy sự kiện nào hết !',
            note: 'Lưu ý',
            valueType: 'Kiểu giá trị',
            value: 'Giá trị',
            apply_type: { title: 'Loại hình áp dụng', auto: 'Tự động', code: 'Mã code' },
            payment_method: { title: 'Phương thức thanh toán', momo: 'Momo', cash: 'Cash' },
            function_apply: {
                title: 'Apply for action',
                create_issue: 'Create issue',
                update_profile: 'Update profile',
                receive_issue: 'Receive issue ',
            },
            roles: { title: 'Đối tượng áp dụng', worker: 'Thợ', customer: 'Chủ nhà' },
            allow_add_category: { title: 'Cho phép thêm categories', allow: 'Cho phép', notAllow: 'Không cho phép' },
            value_type: { title: 'Giảm theo', percent: 'Phần trăm', fixed: 'Fixed' },
            recurring_type: {
                title: 'Áp dụng theo',
                life: 'Trọn đời',
                year: 'Năm',
                month: 'Tháng',
                day: 'Ngày',
                week: 'Tuần',
                hour: 'Giờ',
                quarter: 'Quarter',
                minute: 'Minute',
            },
            title: 'Tiêu đề',
            short_title: 'Tiêu đề phụ',
            code: 'Mã KM',
            from: 'From',
            to: 'To',
            description: 'Mô tả',
            image: 'Image',
            image_detail: 'Image detail',
            category: 'Áp dụng cho categories',
            min_value: 'Giảm từ',
            max_value: 'Giảm tối đa (VNĐ)',
            limit: 'Số lần sử dụng',
            create: 'Create',
            save: 'Save',
            create_success: 'Tạo sự kiện thành công.',
            update_success: 'Chỉnh sửa sự kiện thành công.',
        },
        event_page_detail: {
            title: 'Tiêu đề',
            overView: 'Tổng quan',
            maxValue: 'Giá trị cao nhất',
            minValue: 'Giá trị thấp nhất',
            value: 'Giá trị',
            detail: 'Chi tiết về sự kiện',
            typeAndStatus: 'Thể loại và trạng thái',
            startDate: 'Ngày bắt đầu',
            endDate: 'Ngày kết thúc',
            valueType: 'Loại giá trị',
            code: 'Mã code',
            assign: 'Người nhận công việc',
            event_categories: 'Danh mục sự kiện',
        },
        events_banner_management: {
            title: 'dd',
        },
        user_page: {
            information: 'Thông tin',
            name: 'Tên',
            avatar: 'Hình đại diện',
            phone_number: 'Số điện thoại',
            address: 'Địa chỉ',
            birthday: 'Ngày sinh',
            nothingDataUser: 'Không có người nào cả',
            nothingDataWorker: 'Không có công nhân nào cả',
            index: 'STT',
            role: 'Chức vụ',
            province: 'Thành phố',
            sessionRole: 'Vai trò',
            nothingProvince: 'Chưa nhập vào hệ thống',
            provincePlaceholder: 'Thành phố',
            createdAt: 'Ngày tạo',
            typeUsers: {
                all: 'Tất cả loại',
                updatedAt: 'Ngày cập nhật',
                createdAt: 'Ngày tạo',
                allCategory: 'Tất cả thể loại',
                allProvince: 'Tất cả thành phố',
                worker: 'Thợ',
                customer: 'Khách hàng',
            },
            category: 'Thể loại',
            type: 'Loại',
            userType: 'Chức vụ',
            allUserType: 'Tất cả chức vụ',
        },
        notice_page: {
            draft: 'Thư nháp',
            send: 'Gửi',
            title: 'Tiêu đề',
            nothingDataNotification: 'Không có thông báo nào cả',
            recipientNotification: 'Danh sách người nhận',
            recipientNotificationNumber: 'Số lượng người nhận',
            actorNotification: 'Người gửi thông báo',
            contentNotification: 'Nội dung thông báo',
            issueLinkNotification: 'Chi tiết vấn đề',
            startAt: 'Ngày bắt đầu',
        },
        common: {
            events_once_time_management: "Quản lý sự kiện một lần",
            events_banner_management: 'Quản lý sự kiện banner',
            issues_management: 'Quản lý vấn đề',
            events_management: 'Quản lý sự kiện',
            users_management: 'Quản lý người dùng',
            workers_management: 'Quản lý thợ',
            notice_management: 'Quản lý thông báo',
            list: 'Danh sách',
            add: 'Tạo',
            edit: 'Sửa',
            search: 'Tìm kiếm',
            first_page: 'Đầu',
            last_page: 'Cuối',
            previous_page: 'Trước',
            next_page: 'Sau',
            logout: 'Đăng xuất',
            activated: 'Đã xác nhận',
            unActivated: 'Chưa xác nhận',
            wrongSelectedDate: 'Vui lòng chọn thời gian lớn hơn hiện tại 60 phút!',
            agree: 'Đồng ý',
            cancel: 'Huỷ bỏ',
            deleteConfirmation: 'Bạn có muốn xoá không ?',
            nothing: 'Không có gì',
            noData: 'Không có dữ liệu',
            withdrawals: 'Yêu cầu thanh toán',
            error: 'Có lỗi xảy ra, vui lòng thử lại.',
            sales_management: 'Sales',
            info: 'Infomation',
            skills: 'Kỹ năng thợ',
        },
        withdrawals: {
            index: 'STT',
            name: 'Tên người nhận / Tên người thụ hưởng',
            amount: 'Số tiền',
            paymentMethod: 'Hình thức',
            createdAt: 'Ngày tạo',
            status: 'Trạng thái',
            user: 'Tài khoản yêu cầu',
            detail: 'Chi tiết',
            action: 'Action',
            confirmRequest: 'Xác nhận thanh toán',
            rejectRequest: 'Từ chối thanh toán',
            confirm: 'Lưu',
            cancel: 'Cancel',
            description: 'Mô tả',
            image: 'Hình ảnh',
            failReason: 'Nguyên nhân từ chối',
            close: 'Close',
            addressOffice: 'Địa chỉ trụ sở',
            phoneNumber: 'Số điện thoại',
            accountNumber: 'Số tài khoản',
            bankName: 'Tên ngân hàng',
            confirmSuccess: 'Xác nhận thanh toán thành công',
            rejectSuccess: 'Từ chối thanh toán thành công',
        },
        skill: {
            search: 'Tìm kiếm',
            placeholderSearch: 'Tên / Số điện thoại thợ',
            infoWorker: 'Thông tin thợ',
            category: 'Thể loại',
            status: 'Trạng thái',
            name: 'Tên thợ',
            phone: 'Số điện thoại',
            experience: 'Kinh nghiệm',
            reason: 'Lý do',
            createdAt: 'Ngày tạo',
            detail: 'Chi tiết',
            attachments: 'Tệp đính kèm',
            approve: 'Chấp nhận',
            reject: 'Từ chối',
            confirm: 'Xác nhận',
            cancel: 'Hủy',
            reasonRequired: 'Lý do là bắt buộc',
            approveSuccess: 'Đồng ý thành công',
            rejectSuccess: 'Từ chối thành công',
        },
    },
};

export default Vi;
