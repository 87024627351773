import {
    GET_WORKERS_REQUEST,
    GET_WORKERS_SUCCESS,
    GET_WORKERS_FAILED,
    DELETE_WORKER_REQUEST,
    DELETE_WORKER_SUCCESS,
    DELETE_WORKER_FAILED,
    GET_WORKER_REQUEST,
    GET_WORKER_SUCCESS,
    GET_WORKER_FAILED,
    PROCESSING,
    CLEAR_DATA_WORKER,
    SET_FILTER,
    UPDATE_WORKER_REQUEST,
    UPDATE_WORKER_SUCCESS,
    UPDATE_WORKER_FAILED,
    SET_VERIFY_WORKER,
} from './constants';

export const getWorkersRequest = (payload) => ({ type: GET_WORKERS_REQUEST, payload });
export const getWorkersSuccess = (payload) => ({ type: GET_WORKERS_SUCCESS, payload });
export const getWorkersFailed = (payload) => ({ type: GET_WORKERS_FAILED, payload });

export const getWorkerRequest = (payload) => ({ type: GET_WORKER_REQUEST, payload });
export const getWorkerSuccess = (payload) => ({ type: GET_WORKER_SUCCESS, payload });
export const getWorkerFailed = (payload) => ({ type: GET_WORKER_FAILED, payload });

export const deleteWorkerRequest = (payload) => ({ type: DELETE_WORKER_REQUEST, payload });
export const deleteWorkerSuccess = (payload) => ({ type: DELETE_WORKER_SUCCESS, payload });
export const deleteWorkerFailed = (payload) => ({ type: DELETE_WORKER_FAILED, payload });

export const updateWorkerRequest = (payload) => ({ type: UPDATE_WORKER_REQUEST, payload });
export const updateWorkerSuccess = (payload) => ({ type: UPDATE_WORKER_SUCCESS, payload });
export const updateWorkerFailed = (payload) => ({ type: UPDATE_WORKER_FAILED, payload });

export const setVerifyWorker = (payload) => ({
    type: SET_VERIFY_WORKER,
    payload,
});

export const clearDataWorker = () => ({
    type: CLEAR_DATA_WORKER,
});

export const processing = () => ({
    type: PROCESSING,
});

export const setFilter = (payload) => ({
    type: SET_FILTER,
    payload,
});
