import React from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog/index';
import { useSelector, useDispatch } from 'react-redux';
import { closeDialog } from 'redux/dialog/actions';

const DialogProvider = ({ children }) => {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.Dialog.show);
    const text = useSelector((state) => state.Dialog.text);
    const confirmationCallback = useSelector((state) => state.Dialog.confirmationCallback);

    const confirmDialogAction = () => {
        confirmationCallback();
        dispatch(closeDialog());
    };

    const cancelDialogAction = () => {
        dispatch(closeDialog());
    };

    return (
        <>
            {children}
            {open && (
                <ConfirmationDialog
                    open={open}
                    text={text}
                    confirmAction={confirmDialogAction}
                    cancelAction={cancelDialogAction}
                />
            )}
        </>
    );
};

export default DialogProvider;
