import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as types from './store/constants';
import userAPI from '../../api/usersApi';
import { queryFormat, toastSuccess, formatISODate, expiredToken } from '../../ultils/common';
import { DATE_TYPES, STATUS_CODE } from 'constants/commonConstants';
import {
    getCategoriesFailed,
    getCategoriesSuccess,
    getProvincesFailed,
    getProvincesSuccess,
    getUserFailed,
    getUserRequest,
    getUsersFailed,
    getUsersSuccess,
    getUserSuccess,
    processing,
    updateUserFailed,
} from './store/actions';
import CategoryAPI from '../../api/categoryApi';
import ProvinceAPI from '../../api/provinceApi';
function* getUsersList(action) {
    const { filterUser, accessToken } = action.payload;
    try {
        yield put(processing());
        const _filterUser = {
            ...filterUser,
            province: filterUser.province !== undefined ? filterUser.province.split(' ').join('') : '',
        };
        const query = queryFormat(_filterUser);
        const {
            data: { data, meta },
        } = yield call(userAPI.getUsers, query, accessToken);

        if (meta?.total) {
            const queryParams = queryFormat({ limit: meta.total, from: _filterUser?.from, to: _filterUser?.to });
            const dataResponse = yield call(userAPI.getUsers, queryParams, accessToken);
            yield put(getUsersSuccess({ data, meta, allUsersResponse: dataResponse?.data?.data }));
        } else {
            yield put(getUsersSuccess({ data: [], meta: { total: 0 }, allUsersResponse: [] }));
        }
    } catch (error) {
        yield put(getUsersFailed(error));
        expiredToken(error);
    }
}

function* getUser(action) {
    const { userId, accessToken } = action.payload;
    try {
        const { data } = yield call(userAPI.getUser, userId, accessToken);
        yield put(getUserSuccess(data));
    } catch (error) {
        yield put(getUserFailed(error));
    }
}

function* updateUser(action) {
    const { values, id, accessToken } = action.payload;
    try {
        const { address, name, birthday, gender, phone_number, year_experience, reliability } = values;
        const data = {
            address,
            name,
            birthday: formatISODate(birthday, DATE_TYPES.FORMAT_DATE),
            gender: parseInt(gender),
            phone_number,
            year_experience: year_experience,
            reliability,
        };
        const response = yield call(userAPI.updateUser, id, data, accessToken);
        const { status } = response;
        if (status === STATUS_CODE.UPDATE_SUCCESS) {
            yield put(getUserRequest({ userId: id, accessToken }));
            toastSuccess('Updated success');
        }
    } catch (error) {
        yield put(updateUserFailed(error));
    }
}

function* getCategoriesList(action) {
    const { accessToken } = action.payload;
    try {
        yield put(processing());
        const { data } = yield call(CategoryAPI.getCategories, accessToken);
        yield put(getCategoriesSuccess(data.data));
    } catch (error) {
        yield put(getCategoriesFailed(error));
    }
}

function* getProvincesList(action) {
    const { accessToken } = action.payload;
    try {
        yield put(processing());
        const { data } = yield call(ProvinceAPI.getProvinces, accessToken);
        yield put(getProvincesSuccess(data.data));
    } catch (error) {
        yield put(getProvincesFailed(error));
    }
}

export default function* usersManagementSaga() {
    yield all([
        takeLatest(types.GET_USERS_REQUEST, getUsersList),
        takeLatest(types.GET_USER_REQUEST, getUser),
        takeLatest(types.UPDATE_USER_REQUEST, updateUser),
        takeLatest(types.GET_CATEGORIES_REQUEST, getCategoriesList),
        takeLatest(types.GET_PROVINCES_REQUEST, getProvincesList),
    ]);
}
