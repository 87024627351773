import {
    SET_COMMON_FORM,
    EDIT_FORM_REQUEST,
    EDIT_FORM_SUCCESS,
    EDIT_FORM_FAILED,
    EDIT_ADD_EVENT_FORM_REQUEST,
    EDIT_ADD_EVENT_FORM_SUCCESS,
    EDIT_ADD_EVENT_FORM_FAILED,
    RESET_COMMON_FORM,
} from './constants';

export const setCommonForm = (payload) => ({
    type: SET_COMMON_FORM,
    payload,
});

export const editFormRequest = (payload) => ({
    type: EDIT_FORM_REQUEST,
    payload,
});

export const editFormSuccess = (payload) => ({
    type: EDIT_FORM_SUCCESS,
    payload,
});

export const editFormFailed = (payload) => ({
    type: EDIT_FORM_FAILED,
    payload,
});

export const editAddEVentFormRequest = (payload) => ({
    type: EDIT_ADD_EVENT_FORM_REQUEST,
    payload,
});

export const editAddEVentFormSuccess = (payload) => ({
    type: EDIT_ADD_EVENT_FORM_SUCCESS,
    payload,
});

export const editAddEVentFormFailed = (payload) => ({
    type: EDIT_ADD_EVENT_FORM_FAILED,
    payload,
});

export const resetFieldInForm = () => ({
    type: RESET_COMMON_FORM,
})
