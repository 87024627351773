import React from 'react';
import { Button } from 'reactstrap';
import cs from 'classnames';

const BaseButton = ({ colorType, outline = false, children, onClick, className, disabled = false }) => {
    return (
        <Button
            className={cs('b-button', className)}
            color={colorType}
            outline={outline}
            onClick={onClick}
            disabled={disabled}>
            {children}
        </Button>
    );
};

export default BaseButton;
