// @flow
import {
    ADD_EVENT_SUCCESS,
    EDIT_EVENT_SUCCESS,
    GET_EVENT,
    GET_EVENTS,
    GET_EVENTS_FAILED,
    GET_EVENTS_SUCCESS,
    GET_EVENT_FAILED,
    GET_EVENT_SUCCESS,
    SET_EVENTS_DEFAULT,
} from './constants';

const INIT_STATE = {
    events: [],
    event: {},
    loading: false,
    total: 0,
};

const Events = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_EVENTS:
            return { ...state, loading: true };
        case GET_EVENTS_SUCCESS:
            const { data, meta } = action.payload;
            return { ...state, events: data, total: meta?.total, loading: false, error: null };
        case SET_EVENTS_DEFAULT:
            return { ...state, events: [], loading: false, error: null };
        case GET_EVENTS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_EVENT:
            return { ...state, loading: true };
        case GET_EVENT_SUCCESS:
            return { ...state, event: action.payload, loading: false, error: null };
        case GET_EVENT_FAILED:
            return { ...state, error: action.payload, loading: false };
        case EDIT_EVENT_SUCCESS:
            return {
                ...state,
                event: action.payload,
                loading: false,
                error: null,
            };
        case ADD_EVENT_SUCCESS:
            return {
                ...state,
                events: [action.payload, ...state.events],
                loading: false,
                error: null,
            };
        default:
            return { ...state };
    }
};

export default Events;
