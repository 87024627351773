import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

const HomePage = () => {
    const history = useHistory();
    const { role } = useSelector((state) => state.Auth.user);
    console.log(role);

    useEffect(() => {
        if (role) {
            switch (role) {
                case 'SALE':
                    history.push('/info');
                    return;
                case 'ACCOUNTANT':
                    history.push('/withdrawals');
                    return;
                default:
                    history.push('/notifications');
                    return;
            }
        }
    }, [role]);

    return <div />;
};

export default HomePage;
