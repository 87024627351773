import { LIMIT_IN_PAGE } from 'constants/commonConstants';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import IssuesAPI from '../../api/issuesApi';
import { expiredToken, queryFormat } from '../../ultils/common';
import * as actions from './store/actions';
import * as types from './store/constants';

function* getIssues({ payload: { filter, accessToken, page } }) {
    try {
        yield put(actions.processing());
        const data = queryFormat(filter) + `&limit=${LIMIT_IN_PAGE}&page=${page}`;
        const response = yield call(IssuesAPI.getIssues, data, accessToken);
        yield put(actions.getIssuesSuccess(response.data));
    } catch (error) {
        yield put(actions.getIssueFailed(error));
        expiredToken(error);
    }
}

function* getIssue({ payload: { idIssue, accessToken } }) {
    try {
        const response = yield call(IssuesAPI.getIssue, idIssue, accessToken);
        yield put(actions.getIssueSuccess(response.data));
    } catch (err) {
        yield put(actions.getIssueFailed(err.response.data.msg));
    }
}

export default function* issuesManagementSaga() {
    yield all([takeLatest(types.GET_ISSUES, getIssues), takeLatest(types.GET_ISSUE, getIssue)]);
}
