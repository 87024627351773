import { LIMIT_IN_PAGE_USERS } from 'modules/users/store/constants';
import {
    CLEAR_NOTIFICATION,
    GET_NOTIFICATION,
    GET_NOTIFICATIONS_FAILED,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATION_FAILED,
    GET_NOTIFICATION_SUCCESS,
    PROCESSING,
    SET_FILTER_NOTIFICATION,
    SET_NOTIFICATIONS_DEFAULT,
    SET_ON_EDIT,
    SET_IS_EDIT_NOTIFICATION,
    DELETE_NOTIFICATION_SUCCESS,
} from './constants';

const INIT_STATE = {
    notifications: [],
    notification: {},
    loading: false,
    total: 0,
    onEdit: false,
    users: [],
    filterNotification: {
        page: 1,
        limit: LIMIT_IN_PAGE_USERS,
    },
    isEdit: false,
};

const Notifications = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PROCESSING:
            return { ...state, loading: true };
        case GET_NOTIFICATIONS_SUCCESS:
            const { data, meta } = action.payload;
            return { ...state, notifications: data, loading: false, total: meta.total };
        case GET_NOTIFICATIONS_FAILED:
            return { ...state, loading: false };
        case SET_NOTIFICATIONS_DEFAULT:
            return { ...state, notifications: [], loading: false, error: null };

        case GET_NOTIFICATION:
            return { ...state, loading: true };
        case GET_NOTIFICATION_SUCCESS:
            return { ...state, notification: action.payload, loading: false, error: null };
        case GET_NOTIFICATION_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CLEAR_NOTIFICATION:
            return { ...state, notification: {} };
        case SET_ON_EDIT:
            return { ...state, onEdit: action.payload };

        case SET_FILTER_NOTIFICATION: {
            return {
                ...state,
                filterNotification: action.payload,
            };
        }
        case SET_IS_EDIT_NOTIFICATION: {
            return {
                ...state,
                isEdit: action.payload,
            };
        }
        case DELETE_NOTIFICATION_SUCCESS: {
            const newNotifications = [...state.notifications].filter((el) => el?.id !== action.payload);
            return { ...state, notifications: newNotifications };
        }
        default:
            return state;
    }
};

export default Notifications;
