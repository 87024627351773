export const GET_ISSUES = 'GET_ISSUES';
export const GET_ISSUES_SUCCESS = 'GET_ISSUES_SUCCESS';
export const GET_ISSUES_FAILED = 'GET_ISSUES_FAILED';
export const GET_ISSUE = 'GET_ISSUE';
export const GET_ISSUE_SUCCESS = 'GET_ISSUE_SUCCESS';
export const GET_ISSUE_FAILED = 'GET_ISSUE_FAILED';
export const SET_FILTER = 'SET_FILTER';
export const SET_FILTER_DEFAULT = 'SET_FILTER_DEFAULT';
export const SET_FILTER_DEBOUNCE = 'SET_FILTER_DEBOUNCE';
export const PROCESSING = 'PROCESSING';
export const CLEAR_ISSUE = 'CLEAR_ISSUE';
export const SET_ISSUES_DEFAULT = 'SET_ISSUES_DEFAULT';
