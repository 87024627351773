import { OPEN_MODAL, CLOSE_MODAL, SUBMIT_MODAL } from './constants';

export const setOpenModal = (payload) => ({
    type: OPEN_MODAL,
    payload,
});

export const setCloseModal = () => ({
    type: CLOSE_MODAL,
});

export const submitModal = (payload) => ({
    type: SUBMIT_MODAL,
    payload,
});
