import axios from 'axios';
import { mergeWith } from 'lodash';

// import { stringifyParams } from 'shared/utils';
// import { CookiesStorage } from './cookie';
const API_URL = process.env.REACT_APP_BASE_URL;

export const generateToken = () => ({
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    // todo
});

const defaultOptions = {};

function getApi(path, options = {}, apiURL) {
    return axios.get(`${apiURL || API_URL}/${path.replace(/^\//, '')}`, {
        ...defaultOptions,
        ...options,
        headers: {
            ...options.headers,
            ...generateToken(),
        },
    });
}

function postApi(path, data, options = {}) {
    const headerParams = mergeWith(options.headers, generateToken());

    return axios.post(`${API_URL}/${path.replace(/^\//, '')}`, data, {
        ...defaultOptions,
        ...options,
        headers: headerParams,
    });
}

function putApi(path, data, options = {}) {
    return axios.put(`${API_URL}/${path.replace(/^\//, '')}`, data, {
        ...defaultOptions,
        ...options,
        headers: {
            ...options.headers,
            ...generateToken(),
        },
    });
}

function patchApi(path, data, options = {}) {
    return axios.patch(`${API_URL}/${path.replace(/^\//, '')}`, data, {
        ...defaultOptions,
        ...options,
        headers: {
            ...options.headers,
            ...generateToken(),
        },
    });
}

function deleteApi(path, options = {}) {
    return axios.delete(`${API_URL}/${path.replace(/^\//, '')}`, {
        ...defaultOptions,
        ...options,
        headers: {
            ...options.headers,
            ...generateToken(),
        },
    });
}

function patchWithFormData(path, data, options = {}) {
    const headerParams = mergeWith(options.headers, generateToken());

    return axios.patch(`${API_URL}/${path.replace(/^\//, '')}`, data, {
        ...defaultOptions,
        ...options,
        headers: { 'Content-Type': 'multipart/form-data', ...headerParams },
    });
}

let refreshTokenRequest = null;
async function handleErrorStatus(error) {
    const status = error?.status || error?.response?.status || null;
    const { url } = error?.config;

    switch (status) {
        case 401:
            if (error.config.isRetryRequest) {
                localStorage.clear();
                window.location = '/account/login';
                return Promise.reject(error);
            }
            const isRefresh = !url?.includes('/refresh-token') && !url?.includes('/login');
            const role = localStorage.getItem('role');
            if (
                isRefresh &&
                ((role === 'ACCOUNTANT' && url?.includes('/withdrawal-requests')) || role !== 'ACCOUNTANT')
            ) {
                try {
                    refreshTokenRequest =
                        refreshTokenRequest ||
                        postApi('/refresh-token', { refresh_token: localStorage.getItem('refreshToken') });
                    // 1 --> null --> refresh token
                    // 2 --> refreshToken --> refreshToken

                    const newToken = await refreshTokenRequest;
                    const { accessToken, refreshToken } = newToken.data;
                    refreshTokenRequest = null;
                    // reset token request for the next expiration
                    error.config.isRetryRequest = true;

                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('refreshToken', refreshToken);
                    return axios({
                        ...error.config,
                        headers: { ...error.config?.headers, Authorization: `Bearer ${accessToken}` },
                    });
                } catch (err) {
                    localStorage.clear();
                    window.location = '/account/login';
                    return Promise.reject(error);
                }
            }
            localStorage.clear();
            window.location = '/account/login';
            return Promise.reject(error);

        case 404:
            // window.location = '/pageNotFound';
            return Promise.reject(error);
        case 200:
        case 201:
        case 204:
        case 400:
        case 422:
            return Promise.reject(error);
        default:
            if (url?.includes('/refresh-token')) {
                localStorage.clear();
                window.location = '/account/login';
            }
            return Promise.reject(error);
    }
}

axios.interceptors.response.use(
    (response) => {
        if (response && response.data) {
            return { ...response, data: response.data };
        }
        return response;
    },
    (error) => {
        const errorResponse = error;
        const errorData = errorResponse?.response?.data || {};
        const errorMessage = errorData?.error?.message || errorData?.message;
        const errors = errorData?.error?.errors;
        errorResponse.response = {
            ...errorResponse.response,
            data: {
                ...errorData.error,
                message: errors?.length ? errors[0]?.message : errorMessage,
                errors: errorData?.error?.errors || [],
            },
        };

        return handleErrorStatus(errorResponse);
    }
);

axios.interceptors.request.use((config) => {
    const newConfig = { ...config };
    if (newConfig.headers['Content-Type'] === 'multipart/form-data') return newConfig;
    if (config.params) {
        newConfig.params = config.params;
    }
    if (config.data) {
        newConfig.data = config.data;
    }
    return newConfig;
});

// axios.defaults.paramsSerializer = (params) =>
//     stringifyParams({
//         params: { ...params },
//         option: {
//             encode: !isNil(params?.tags) || false,
//         },
//     });

const Api = {
    get: getApi,
    post: postApi,
    put: putApi,
    delete: deleteApi,
    patch: patchApi,
    patchWithFormData,
};

export default Api;
