// @flow
import { LIMIT_IN_PAGE } from 'constants/commonConstants';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import EventsAPI from '../../api/eventsApi';
import {
    checkValidUrl,
    convertImageUrlToFile,
    expiredToken,
    queryFormat,
    toastSuccess,
} from '../../ultils/common';
import { getEvent, getEventFailed, getEventsFailed, getEventsSuccess, getEventSuccess, addEventFailed } from './store/actions';
import { ADD_EVENT_REQUEST, GET_EVENT, GET_EVENTS } from './store/constants';
function* getEventsAction({ payload: { filter, accessToken, page } }) {
    try {
        const data = queryFormat(filter) + `&limit=${LIMIT_IN_PAGE}&page=${page}`;
        const response = yield call(EventsAPI.getEvents, data, accessToken);
        yield put(getEventsSuccess(response.data));
    } catch (err) {
        yield put(getEventsFailed(err));
        expiredToken(err);
    }
}

function* getEventAction({ payload: { idEvent, accessToken } }) {
    try {
        const response = yield call(EventsAPI.getEvent, idEvent, accessToken);
        yield put(getEventSuccess(response.data));
    } catch (err) {
        yield put(getEventFailed(err));
    }
}

function* addEditEventAction({ payload }) {
    // eslint-disable-next-line no-unused-vars
    const { id, values, accessToken, filter } = payload;
    try {
        const url = values.file;
        const urlFileDetail = values.fileDetail;
        const file = id ? (checkValidUrl(url) ? yield call(convertImageUrlToFile, url, 'events.jpg') : url) : url;
        const fileDetail = id
            ? checkValidUrl(url)
                ? yield call(convertImageUrlToFile, urlFileDetail, 'events.jpg')
                : urlFileDetail
            : urlFileDetail;

        const data = {
            type: values.type,
            code: values.code,
            title: values.title,
            description: values.description,
            value: parseInt(values.value),
            minValue: parseInt(values.minValue),
            maxValue: parseInt(values.maxValue),
            status: parseInt(values.status),
            from: values.from,
            to: values.to,
            valueType: values.valueType,
            file,
            fileDetail,
            allowAddCategory: values.allowAddCategory,
            applyType: values.applyType,
            categoryIds: values.categories,
            functionApplied: values.functionApplied,
            note: values.note,
            roles: values.roles,
            paymentMethodDefault: values.paymentMethodDefault,
            recurringType: values.recurringType,
            shortTitle: values.shortTitle,
        };

        const formData = new FormData();
        for (const key in data) {
            Array.isArray(data[key])
                ? formData.append(key, JSON.stringify(data[key]))
                : formData.append(key, data[key]);
        }

        if (id.length === 0 || !id.trim()) {
            yield call(EventsAPI.postCreateNewEvent, formData, accessToken);
            toastSuccess('Create Event Successfully');
            window.location.replace('/events/list');
        } else {
            yield call(EventsAPI.patchUpdateEvent, id, formData, accessToken);
            yield put(getEvent({ idEvent: id, accessToken }));
        }
    } catch (err) {
        yield put(addEventFailed(err))
    }
}

export function* watchAddEditEvent() {
    yield takeLatest(ADD_EVENT_REQUEST, addEditEventAction);
}

export function* watchGetEvent() {
    yield takeLatest(GET_EVENT, getEventAction);
}

export function* watchGetEvents() {
    yield takeEvery(GET_EVENTS, getEventsAction);
}

function* eventSaga() {
    yield all([fork(watchGetEvents), fork(watchGetEvent), fork(watchAddEditEvent)]);
}

export default eventSaga;
