import { RESET_COMMON_FORM, SET_COMMON_FORM } from './constants';

const initState = {
    processing: false,
    input: {
        fields: [],
    },
    inputSelect: {
        fields: [],
        isActive: false,
        options: [],
    },
    inputDateRange: {
        fields: [],
        isActive: false,
    },
    inputCheckBoxSelection: {
        fields: [],
        isActive: false,
    },
    inputDropDownSelection: {
        fields: [],
        isActive: false,
    },
    inputEditors: {
        fields: [],
        isActive: false,
    },
    formik: {
        idSelected: '',
        initialValues: {},
        validationSchema: null,
        values: {},
    },
    custom: [],
    id: '',
};

const ModalReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_COMMON_FORM: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case RESET_COMMON_FORM: {
            return {
                ...state,
                processing: false,
                input: {
                    fields: [],
                },
                inputSelect: {
                    fields: [],
                    isActive: false,
                    options: [],
                },
                inputDateRange: {
                    fields: [],
                    isActive: false,
                },
                inputCheckBoxSelection: {
                    fields: [],
                    isActive: false,
                },
                inputDropDownSelection: {
                    fields: [],
                    isActive: false,
                },
                inputEditors: {
                    fields: [],
                    isActive: false,
                },
                formik: {
                    idSelected: '',
                    initialValues: {},
                    validationSchema: null,
                    values: {},
                },
                custom: [],
                id: '',
            };
        }

        default:
            return { ...state };
    }
};

export default ModalReducer;
