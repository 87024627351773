import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import FormActions from '../FormActions';
import useSelectorModal from './store/selector';

const BaseModal = () => {
    const { modal } = useSelectorModal();
    const { children, isOpen, label, isForm, handleSubmit } = modal;

    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader>{label}</ModalHeader>
            <ModalBody>{children}</ModalBody>
            {!isForm && <FormActions onSubmit={handleSubmit} />}
        </Modal>
    );
};

export default BaseModal;
