import { toObject } from 'ultils/common';

export const GET_WORKERS_REQUEST = 'GET_WORKERS_REQUEST';
export const GET_WORKERS_SUCCESS = 'GET_WORKERS_SUCCESS';
export const GET_WORKERS_FAILED = 'GET_WORKERS_FAILED';

export const GET_WORKER_REQUEST = 'GET_WORKER_REQUEST';
export const GET_WORKER_SUCCESS = 'GET_WORKER_SUCCESS';
export const GET_WORKER_FAILED = 'GET_WORKER_FAILED';

export const DELETE_WORKER_REQUEST = 'DELETE_WORKER_REQUEST';
export const DELETE_WORKER_SUCCESS = 'DELETE_WORKER_SUCCESS';
export const DELETE_WORKER_FAILED = 'DELETE_WORKER_FAILED';

export const UPDATE_WORKER_REQUEST = 'UPDATE_WORKER_REQUEST';
export const UPDATE_WORKER_SUCCESS = 'UPDATE_WORKER_SUCCESS';
export const UPDATE_WORKER_FAILED = 'UPDATE_WORKER_FAILED';

export const CLEAR_DATA_WORKER = 'CLEAR_DATA_WORKER';
export const SET_FILTER = 'SET_FILTER';

export const PROCESSING = 'PROCESSING';

export const LIMIT_IN_PAGE_WORKERS = 12;

export const SET_VERIFY_WORKER = 'SET_VERIFY_WORKER';

export const DEFAULT_MODAL = {
    OPEN: true,
    CLOSE: false,
    HAS_FORM: true,
    NO_FORM: false,
};

export const WORKER_ROLES = ['user', 'admin'];

export const INIT_VALUE_FORM_EDIT_WORKER = {
    name: '',
    address: '',
    phone_number: '',
    birthday: '',
    gender: '',
    year_experience: '',
    reliability: '',
    is_verified: false,
};

export const FIELDS_EDIT_WORKER = {
    NAME: 'name',
    ADDRESS: 'address',
    PHONE_NUMBER: 'phone_number',
    BIRTHDAY: 'birthday',
    GENDER: 'gender',
    YEAR_EXPERIENCE: 'year_experience',
    RELIABILITY: 'reliability',
    IS_VERIFIED: 'is_verified',
};

export const GENDER_TYPE = [
    { id: 0, value: 0, name: 'MALE' },
    { id: 1, value: 1, name: 'FEMALE' },
    { id: 2, value: 2, name: 'OTHER' },
];

export const VERIFY_WORKER_TYPES = [
    {
        id: 0,
        value: false,
    },
    {
        id: 1,
        value: true,
    },
];

export const VERIFY_WORKER_TYPES_OBJECT = toObject(VERIFY_WORKER_TYPES, 'id');

export const VERIFY_WORKER_TYPES_OBJECT_REQUEST = toObject(VERIFY_WORKER_TYPES);
