import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';

import { isUserAuthenticated } from '../helpers/authUtils';
import * as layoutConstants from '../constants/layout';
import { allFlattenRoutes as routes } from './index';
import BaseModal from '../components/BaseModal';
import { getUserInformation, loginUserSuccess, refreshPage } from '../redux/actions';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import history from '../ultils/history';
import DialogProvider from '../components/DialogProvider/DialogProvider';
import PageNotFound from 'components/PageNotFound/PageNotFound';
const loading = () => <div></div>;

// All layouts/containers
const AuthLayout = Loadable({
    loader: () => import('../layouts/Auth'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

const VerticalLayout = Loadable({
    loader: () => import('../layouts/Vertical'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

const HorizontalLayout = Loadable({
    loader: () => import('../layouts/Horizontal'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

class Routes extends Component {
    // returns the layout
    getLayout = () => {
        if (!isUserAuthenticated()) return AuthLayout;

        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    componentDidMount() {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            const user = getUserInformation(accessToken);
            this.props.setUserInReduxAction({ user, accessToken });
        } else {
            history.push('/account/login');
        }
    }

    render() {
        const Layout = this.getLayout();

        // rendering the router with layout
        return (
            <BrowserRouter>
                <Layout {...this.props}>
                    <DialogProvider>
                        <BaseModal />
                        <ToastContainer />
                        <Switch>
                            {routes.map((ItemRoute, index) => {
                                const { path, roles, exact, component: ComponentRoute } = ItemRoute;
                                return !ItemRoute.children ? (
                                    // eslint-disable-next-line react/jsx-pascal-case
                                    <ItemRoute.route
                                        key={index}
                                        path={path}
                                        roles={roles}
                                        exact={exact}
                                        component={ComponentRoute}
                                        currentRole={this.props.user?.role || ''}
                                    />
                                ) : null;
                            })}
                            <Route path="*">
                                <PageNotFound />
                            </Route>
                        </Switch>
                    </DialogProvider>
                </Layout>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        layout: state.Layout,
        user: state.Auth.user,
        modal: state.BaseModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        refreshPageAction: () => dispatch(refreshPage()),
        setUserInReduxAction: ({ user, accessToken }) => dispatch(loginUserSuccess({ user, accessToken })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
