import React from 'react';
// import { useSelector } from 'react-redux';

import Routes from './routes/Routes';

// setup fake backend
// import { configureFakeBackend } from './helpers';

// Themes

// default
import './assets/scss/theme.scss';

// dark
// import './assets/scss/theme-dark.scss';

// rtl
// import './assets/scss/theme-rtl.scss';

// configure fake backend
// configureFakeBackend();

/**
 * Main app component
 */
const App = () => {
    // const { role } = useSelector((state) => state.Auth.user);
    // useEffect(() => {
    //     if (role === 'SALE') {
    //         console.log(role);
    //         document.body.classList.add('role-sale');
    //     }
    // }, [role]);
    return <Routes />;
};

export default App;
