// @flow
import { all } from 'redux-saga/effects';
import eventSaga from '../modules/events/saga';
import usersManagementSaga from '../modules/users/saga';
import appMenuSaga from './appMenu/saga';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import issuesSaga from '../modules/issues/saga';
import workersManagementSaga from 'modules/workers/saga';
import notificationsManagementSaga from 'modules/notifications/saga';

export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        layoutSaga(),
        appMenuSaga(),
        usersManagementSaga(),
        eventSaga(),
        issuesSaga(),
        workersManagementSaga(),
        notificationsManagementSaga(),
    ]);
}
