import { useSelector } from 'react-redux';

const useSelectorModal = () => {
    const modal = useSelector((state) => state.BaseModal);
    return {
        modal,
    };
};

export default useSelectorModal;
