export const LIMIT_IN_PAGE = 9;
export const DEFAULT_IMAGE =
    'https://png.pngtree.com/png-clipart/20201208/original/pngtree-creative-design-cartoon-emoji-package-event-png-image_5509393.jpg';

export const MODE = {
    RANGE: 'range',
};

export const DATE_TYPES = {
    FORMAT_DATE: 'YYYY-MM-DD',
    FORMAT_DATE_FLATPICKR: 'Y-m-d',
    FORMAT_DATE_TIME_FLATPICKR: 'Y-m-d H:i',
    FORMAT_DATE_TIME: 'YYYY-MM-DD h:mm:ss',
    FORMAT_DATE_IOS: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
    FORMAT_DATE_TIME_ZONE: 'YYYY-MM-DD HH:mm:ss',
};

export const STATUS_CONST = {
    OPEN: 'OPEN',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_PAYMENT: 'WAITING_PAYMENT',
    CHATTING: 'CHATTING',
    CANCELLED: 'CANCELLED',
    DONE: 'DONE',
    WAITING_VERIFY: 'WAITING_VERIFY',
    NEW_ISSUE: 'NEW_ISSUE',
    REQUEST_SUPPORTING: 'REQUEST_SUPPORTING',
    CANCEL_SUPPORT: 'CANCEL_SUPPORT',
};

export const IMAGE_TYPES = ['image/jpeg', 'image/png'];

export const STATUS_CODE = {
    UPDATE_SUCCESS: 204,
};

// eslint-disable-next-line no-useless-escape
export const regexAllPTags = '/<ps*.*></ps*.*>/g';

export const REGEX_PHONE = /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/;
