import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import En from './translation/en';
import Vi from './translation/vi';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'vi',
        fallbackLng: 'en',
        debug: true,
        resources: {
            en: {
                ...En,
            },
            vi: {
                ...Vi,
            },
        },
        // keySeparator: false, // we do not use keys in form messages.welcome

        // interpolation: {
        //     escapeValue: false, // react already safes from xss
        // },
    });

export default i18n;
