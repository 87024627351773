import NotificationAPI from 'api/notificationApi';
import { notificationsRoutes } from 'constants/routeConstants';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { decodeHtml, queryFormat, removeHTMLTags, toastSuccess } from '../../ultils/common';
import * as actions from './store/actions';
import * as types from './store/constants';
function* getNotifications({ payload: { filterNotification, accessToken } }) {
    try {
        yield put(actions.processing());
        const query = queryFormat(filterNotification);
        const {
            data: { data, meta },
        } = yield call(NotificationAPI.getNotifications, query, accessToken);
        yield put(actions.getNotificationsSuccess({ data, meta }));
    } catch (error) {
        yield put(actions.getNotificationsFailed(error));
    }
}

function* getNotification({ payload: { idNotification, accessToken } }) {
    try {
        const response = yield call(NotificationAPI.getNotification, idNotification, accessToken);
        yield put(actions.getNotificationSuccess(response.data));
    } catch (error) {
        yield put(actions.getNotificationFailed(error));
    }
}

function* deleteNotification({ payload: { idNotification, accessToken } }) {
    try {
        yield call(NotificationAPI.deleteNotification, idNotification, accessToken);
        yield put(actions.deleteNotificationSuccess(idNotification));
    } catch (err) {
        yield put(actions.deleteNotificationFailed(err));
    }
}

function* handleAddNewNotification({
    payload: { data, accessToken, history, isEdit, idNotification, successCallback },
}) {
    const _data = {
        ...data,
        body: data.body.substring(0, data.body.length - 1),
    };
    const serializeData = {..._data, body: decodeHtml(removeHTMLTags(_data.body))};
    try {
        if (!isEdit) {
            yield call(NotificationAPI.postCreateNewNotification, serializeData, accessToken);
        } else {
            yield call(NotificationAPI.patchUpdateNotification, idNotification, serializeData, accessToken);
        }
        history.push(notificationsRoutes.LIST_NOTIFICATION);
        successCallback && successCallback();
        toastSuccess(`${isEdit ? 'Edit' : 'Add'} notification successfully!`);
    } catch (err) {
        successCallback && successCallback();
        yield put(actions.addNewNotificationFailed(err));
    }
}

export default function* notificationsManagementSaga() {
    yield all([
        takeLatest(types.GET_NOTIFICATIONS, getNotifications),
        takeLatest(types.GET_NOTIFICATION, getNotification),
        takeLatest(types.ADD_NEW_NOTIFICATION, handleAddNewNotification),
        takeLatest(types.DELETE_NOTIFICATION, deleteNotification),
    ]);
}
