import {
    ADD_EVENT_FAILED,
    ADD_EVENT_REQUEST,
    ADD_EVENT_SUCCESS,
    EDIT_EVENT_FAILED,
    EDIT_EVENT_SUCCESS,
    GET_EVENT,
    GET_EVENTS,
    GET_EVENTS_FAILED,
    GET_EVENTS_SUCCESS,
    GET_EVENT_FAILED,
    GET_EVENT_SUCCESS,
} from './constants';

export const getEvents = (payload) => ({
    type: GET_EVENTS,
    payload: payload, // any keyword
});

export const getEventsSuccess = (payload) => ({
    type: GET_EVENTS_SUCCESS,
    payload: payload,
});

export const getEventsFailed = (payload) => ({
    type: GET_EVENTS_FAILED,
    payload: payload,
});

export const getEvent = (payload) => ({
    type: GET_EVENT,
    payload,
});

export const getEventSuccess = (payload) => ({
    type: GET_EVENT_SUCCESS,
    payload: payload,
});

export const getEventFailed = (payload) => ({
    type: GET_EVENT_FAILED,
    payload: payload,
});

export const editEventSuccess = (payload) => ({
    type: EDIT_EVENT_SUCCESS,
    payload: payload,
});

export const editEventFailed = (payload) => ({
    type: EDIT_EVENT_FAILED,
    payload: payload,
});

export const addEventRequest = (payload) => ({
    type: ADD_EVENT_REQUEST,
    payload: payload,
});

export const addEventSuccess = (payload) => ({
    type: ADD_EVENT_SUCCESS,
    payload: payload,
});

export const addEventFailed = (payload) => ({
    type: ADD_EVENT_FAILED,
    payload: payload,
});
