export const SET_COMMON_FORM = 'SET_COMMON_FORM';
export const RESET_COMMON_FORM = 'RESET_COMMON_FORM';

export const EDIT_FORM_REQUEST = 'EDIT_FORM_REQUEST';
export const EDIT_FORM_SUCCESS = 'EDIT_FORM_SUCCESS';
export const EDIT_FORM_FAILED = 'EDIT_FORM_FAILED';

export const EDIT_ADD_EVENT_FORM_REQUEST = 'EDIT_ADD_EVENT_FORM_REQUEST';
export const EDIT_ADD_EVENT_FORM_SUCCESS = 'EDIT_ADD_EVENT_FORM_SUCCESS';
export const EDIT_ADD_EVENT_FORM_FAILED = 'EDIT_ADD_EVENT_FORM_FAILED';

export const FORM_TYPES = {
    TEXT: 'text',
    NUMBER: 'number',
    FILE: 'file',
    FILE_DETAIL: 'fileDetail',
};

export const ID_MODAL = {
    USER: 'user',
    EVENT: 'event',
    WORKER: 'worker',
};

export const LIST_FORM_CUSTOM = [ID_MODAL.WORKER];
