import { EditorState } from 'draft-js';

/* AUTH */
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILED = 'GET_EVENTS_FAILED';
export const GET_EVENT = 'GET_EVENT';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAILED = 'GET_EVENT_FAILED';
export const SET_FILTER = 'SET_FILTER';
export const SET_FILTER_DEFAULT = 'SET_FILTER_DEFAULT';
export const SET_FILTER_DEBOUNCE = 'SET_FILTER_DEBOUNCE';

export const SET_EVENTS_DEFAULT = 'SET_EVENTS_DEFAULT';

export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAILED = 'EDIT_EVENTS_FAILED';

export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAILED = 'ADD_EVENTS_FAILED';

export const DEFAULT_MODAL = {
    OPEN: true,
    CLOSE: false,
    HAS_FORM: true,
    NO_FORM: false,
};

export const EVENT_TYPES = ['bonus', 'voucher', 'discount'];

export const INIT_VALUE_FORM_EVENT = {
    type: 'bonus',
    code: '',
    title: '',
    description: EditorState.createEmpty(),
    note: EditorState.createEmpty(),
    value: '',
    valueType: 'fixed',
    minValue: null,
    maxValue: null,
    status: null,
    limit: null,
    from: '',
    to: '',
    imagePath: '',
    allowAddCategory: 'notAccept',
    paymentMethodDefault: 'cash',
    recurringType: 'life',
    functionApplied: 'create_issue',
    roles: [],
    categories: [],
};
