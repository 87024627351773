import {
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    GET_USERS_FAILED,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILED,
    PROCESSING,
    CLEAR_DATA_USER,
    SET_FILTER,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILED,
    SET_DATA_USERS_CHANGE,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAILED,
    GET_PROVINCES_REQUEST,
    GET_PROVINCES_SUCCESS,
    GET_PROVINCES_FAILED,
    SET_FILTER_USER_TABLE,
} from './constants';

export const getUsersRequest = (payload) => ({ type: GET_USERS_REQUEST, payload });
export const getUsersSuccess = (payload) => ({ type: GET_USERS_SUCCESS, payload });
export const getUsersFailed = (payload) => ({ type: GET_USERS_FAILED, payload });

export const getUserRequest = (payload) => ({ type: GET_USER_REQUEST, payload });
export const getUserSuccess = (payload) => ({ type: GET_USER_SUCCESS, payload });
export const getUserFailed = (payload) => ({ type: GET_USER_FAILED, payload });

export const getCategoriesRequest = (payload) => ({ type: GET_CATEGORIES_REQUEST, payload });
export const getCategoriesSuccess = (payload) => ({ type: GET_CATEGORIES_SUCCESS, payload });
export const getCategoriesFailed = (payload) => ({ type: GET_CATEGORIES_FAILED, payload });

export const getProvincesRequest = (payload) => ({ type: GET_PROVINCES_REQUEST, payload });
export const getProvincesSuccess = (payload) => ({ type: GET_PROVINCES_SUCCESS, payload });
export const getProvincesFailed = (payload) => ({ type: GET_PROVINCES_FAILED, payload });

export const deleteUserRequest = (payload) => ({ type: DELETE_USER_REQUEST, payload });
export const deleteUserSuccess = (payload) => ({ type: DELETE_USER_SUCCESS, payload });
export const deleteUserFailed = (payload) => ({ type: DELETE_USER_FAILED, payload });

export const updateUserRequest = (payload) => ({ type: UPDATE_USER_REQUEST, payload });
export const updateUserSuccess = (payload) => ({ type: UPDATE_USER_SUCCESS, payload });
export const updateUserFailed = (payload) => ({ type: UPDATE_USER_FAILED, payload });

export const setDataUsersChange = (payload) => ({ type: SET_DATA_USERS_CHANGE, payload });

export const clearDataUser = () => ({
    type: CLEAR_DATA_USER,
});

export const processing = () => ({
    type: PROCESSING,
});

export const setFilter = (payload) => ({
    type: SET_FILTER,
    payload,
});

export const setFilterUserTable = (payload) => ({
    type: SET_FILTER_USER_TABLE,
    payload,
});
