import Api from './api';
import axiosClient from './axiosClient';
import { queryFormat } from 'ultils/common';

const URL_CATEGORY = 'categories';

const CategoryAPI = {
    getCategories: (data, token) => {
        const url = URL_CATEGORY;
        return axiosClient.get(url, token);
    },
    getListCategory: (params) => {
        const url = `${URL_CATEGORY}${queryFormat(params || {})}`;
        return Api.get(url);
    },
};

export default CategoryAPI;
