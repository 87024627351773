// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    REFRESH_PAGE,
    REFRESH_PAGE_SUCCESS,
    REFRESH_PAGE_FAILED,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    CLEAR_ERROR,
} from './constants';
import jwt_decode from 'jwt-decode';

export const loginUser = (phone_number, password, history) => ({
    type: LOGIN_USER,
    payload: { phone_number, password, history }, // any keyword
});

export const loginUserSuccess = (data) => ({
    type: LOGIN_USER_SUCCESS,
    payload: data,
});

export const loginUserFailed = (error) => ({
    type: LOGIN_USER_FAILED,
    payload: error,
});

export const registerUser = (username, email, password, history) => ({
    type: REGISTER_USER,
    payload: { username, email, password, history },
});

export const registerUserSuccess = () => ({
    type: REGISTER_USER_SUCCESS,
    payload: {},
});

export const registerUserFailed = (error) => ({
    type: REGISTER_USER_FAILED,
    payload: error,
});

export const logoutUser = () => ({
    type: LOGOUT_USER,
});

export const forgetPassword = (email, history) => ({
    type: FORGET_PASSWORD,
    payload: { email, history },
});

export const forgetPasswordSuccess = () => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: {},
});

export const forgetPasswordFailed = (error) => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error,
});

export const resetPassword = (password, accessToken, history) => ({
    type: RESET_PASSWORD,
    payload: { password, accessToken, history },
});

export const resetPasswordSuccess = () => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: {},
});

export const resetPasswordFailed = (error) => ({
    type: RESET_PASSWORD_FAILED,
    payload: error,
});

export const refreshPage = () => ({
    type: REFRESH_PAGE,
    payload: {}, // any keyword
});

export const refreshPageSuccess = (user) => ({
    type: REFRESH_PAGE_SUCCESS,
    payload: user,
});

export const refreshPageFailed = (error) => ({
    type: REFRESH_PAGE_FAILED,
    payload: error,
});

export const clearErrorPage = () => ({
    type: CLEAR_ERROR,
    payload: {},
});

export const getUserInformation = (token) => {
    return jwt_decode(token);
};
