import Api from './api';
import { queryFormat } from 'ultils/common';

const URL_EVENTS = 'events';

const EventsAPI = {
    getEvents: (params) => {
        const url = `${URL_EVENTS}${queryFormat(params || {})}`;
        return Api.get(url);
    },
    postCreateNewEvent: (data) => {
        const url = URL_EVENTS;
        return Api.post(url, data);
    },
    getEvent: (idEvent) => {
        const url = `${URL_EVENTS}/${idEvent}`;
        return Api.get(url);
    },
    patchUpdateEvent: (idEvent, data) => {
        const url = `${URL_EVENTS}/${idEvent}`;
        return Api.patch(url, data);
    },
    deleteEvent: (idEvent) => {
        const url = `${URL_EVENTS}/${idEvent}`;
        return Api.delete(url);
    },
    getCategories: (params) => {
        const url = `categories${queryFormat(params || {})}`;
        return Api.get(url);
    },
    getAppliedFunctions: (params) => {
        const url = `${URL_EVENTS}/applied-functions${queryFormat(params || {})}`;
        return Api.get(url);
    },
};

export default EventsAPI;
