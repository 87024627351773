// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import AuthAPI from '../../api/authApi';
import { toastSuccess } from '../../ultils/common';
import {
    forgetPasswordFailed,
    forgetPasswordSuccess,
    getUserInformation,
    loginUserFailed,
    loginUserSuccess,
    refreshPageFailed,
    refreshPageSuccess,
    registerUserSuccess,
    resetPasswordFailed,
    resetPasswordSuccess,
} from './actions';
import { FORGET_PASSWORD, LOGIN_USER, LOGOUT_USER, REFRESH_PAGE, REGISTER_USER, RESET_PASSWORD } from './constants';

/**
 * Login the user
 * @param {*} payload - email and password
 */
function* login({ payload: { phone_number, password, history } }) {
    try {
        const data = { phone_number, password };
        const response = yield call(AuthAPI.postSignIn, data);
        const accessToken = response?.data?.accessToken;
        const refreshToken = response?.data?.refreshToken;

        const user = getUserInformation(accessToken);

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('role', user?.role);
        yield put(loginUserSuccess({ user, accessToken }));
        const { role } = user;

        if (role === 'CONSULTING') {
            yield call(() => {
                history.push(`/skills`);
            });
        }
        else if (role === 'SALE') {
            yield call(() => {
                history.push(`/info`);
            });
        } else {
            const isAccountant = user?.role === 'ACCOUNTANT';

            yield call(() => {
                history.push(isAccountant ? '/withdrawals' : '/notifications');
            });
        }
    } catch (err) {
        yield put(loginUserFailed(err.response.data.message));
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout() {
    try {
        yield localStorage.clear();
        yield window.location.replace('/account/login');
    } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { username, email, password, history } }) {
    try {
        const data = { username, email, password };

        const response = yield call(AuthAPI.postSignUp, data);
        yield put(registerUserSuccess());

        toastSuccess(response.msg);

        yield call(() => {
            history.push('/account/login');
        });
    } catch (err) {
        yield put(loginUserFailed(err.response.data.msg));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email, history } }) {
    try {
        const data = { email };

        const response = yield call(AuthAPI.postForgot, data);

        yield put(forgetPasswordSuccess());

        yield call(() => {
            history.push('/account/login');
        });

        toastSuccess(response.msg);
    } catch (err) {
        yield put(forgetPasswordFailed(`${err.response.data.msg}`));
    }
}

/**
 * Refresh page
 *
 */

function* refreshPage() {
    try {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        if (accessToken && refreshToken) {
            const data = { refresh_token: refreshToken };
            const decode = yield call(AuthAPI.postRefreshToken, data, accessToken);

            localStorage.setItem('accessToken', decode.data.accessToken);
            localStorage.setItem('refreshToken', decode.data.refreshToken);

            const user = getUserInformation(decode.data.accessToken);
            yield put(refreshPageSuccess({ user, accessToken: decode.data.accessToken }));
        }
    } catch (err) {
        yield put(refreshPageFailed(err.response?.data.message));
    }
}

/**
 * Reset password
 *
 */

function* resetPasswordPage({ payload: { password, accessToken, history } }) {
    try {
        const data = {
            token: accessToken,
            password,
        };
        const response = yield call(AuthAPI.postResetPassword, data);
        toastSuccess(response.msg);

        yield put(resetPasswordSuccess());

        yield call(() => {
            history.push('/account/login');
        });
    } catch (err) {
        yield put(resetPasswordFailed(err.response.data.msg));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchRefreshPage() {
    yield takeEvery(REFRESH_PAGE, refreshPage);
}

export function* watchResetPasswordPage() {
    yield takeEvery(RESET_PASSWORD, resetPasswordPage);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgetPassword),
        fork(watchRefreshPage),
        fork(watchResetPasswordPage),
    ]);
}

export default authSaga;
