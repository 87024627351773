// @flow

import Events from 'modules/events/store/reducers';
import Issues from 'modules/issues/store/reducer';
import Notifications from 'modules/notifications/store/reducer';
import UsersManagement from 'modules/users/store/reducer';
import WorkersManagement from 'modules/workers/store/reducer';
import { combineReducers } from 'redux';
import BaseModal from '../components/BaseModal/store/reducer';
import CommonForm from '../components/CommonForm/store/reducer';
import AppMenu from './appMenu/reducers';
import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Dialog from './dialog/reducers';

export default combineReducers({
    Auth,
    AppMenu,
    Layout,
    Events,
    UsersManagement,
    BaseModal,
    CommonForm,
    Issues,
    WorkersManagement,
    Notifications,
    Dialog
});
