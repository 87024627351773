const En = {
    translation: {
        setting_languages: {
            en: 'English',
            vi: 'Vietnamese',
            change_language: 'Change language',
        },
        issues_page: {
            issues: 'Issues',
            list: 'List',
            search_placeholder: 'Enter to search',
            type_placeholder: 'Select status',
            type_payment_placeholder: 'Select payment',
            type: 'Status',
            type_payment: 'Payment',
            type_issues: {
                all: 'All',
                done: 'Done',
                cancelled: 'Cancelled',
                open: 'Open',
                waiting_verify: 'Waiting Verify',
                system_verifying: 'System Verifying',
                chatting: 'Chatting',
                inprogress: 'Inprogress',
                system: 'System',
                momo: 'Momo',
                cash: 'Cash',
            },
            nothingData: 'There is no issue here !',
            information: 'Information',
            location: 'Location',
            status: 'Status',
            payment_method: 'Payment method',
            date: 'Date',
            requestUser: 'Request Users',
            attachedFiles: 'Attached Files',
            creator: 'Creator',
            name: 'Name',
            gender: 'Gender',
            sessionRole: 'Session role',
            role: 'Role',
            address: 'Address',
            phone: 'Phone number',
            birthday: 'Birthday',
            contactInfo: 'Contact Informations',
            total: 'Total',
            title: 'Title',
            amount: 'Amount',
            totalWorkerReceive: 'Total worker receive',
            totalIntoWalletWorker: 'Total in-Wallet worker receive',
            totalCustomerPaid: 'Total customer paid',
            fee: 'Fee',
            cost: 'Worker cost',
            surveyFee: 'Survey fee',
            distanceFee: 'Distance fee',
            discount: 'Discount',
            paymentWorker: 'Payment worker receive',
            paymentCustomer: 'Payment of customer',
            totalBill: 'Total bill',
            materialCost: 'Material cost',
        },
        events_page: {
            events: 'Events',
            search_placeholder: 'Enter to search...',
            type_placeholder: 'Select type',
            type: 'Type',
            type_select: 'Select percent sale',
            type_status: 'Select status',
            type_start_date: 'Select start date',
            type_end_date: 'Select end date',
            startDate: 'Start date',
            endDate: 'End date',
            type_events: {
                title: 'Type',
                all: 'All',
                voucher: 'Voucher',
                bonus: 'Bonus',
                discount: 'Discount',
                special: 'Special',
                lw100: 'Lower than 100%',
                lw90: 'Lower than 90%',
                lw80: 'Lower than 80%',
                lw70: 'Lower than 70%',
                lw60: 'Lower than 60%',
                lw50: 'Lower than 50%',
                lw40: 'Lower than 40%',
                lw30: 'Lower than 30%',
                lw20: 'Lower than 20%',
                lw10: 'Lower than 10%',
            },
            status: {
                title: 'Status',
                all: 'All',
                active: 'Active',
                inActive: 'InActive',
            },
            nothingData: 'There is no event here !',
            note: 'Note',
            valueType: 'Value Type',
            value: 'Value',
            apply_type: { title: 'Apply type', auto: 'Auto', code: 'Code' },
            payment_method: { title: 'Payment method default', momo: 'Momo', cash: 'Cash' },
            function_apply: {
                title: 'Apply for action',
                create_issue: 'Create issue',
                update_profile: 'Update profile',
                receive_issue: 'Receive issue ',
            },
            roles: { title: 'Roles', worker: 'Worker', customer: 'Customer' },
            allow_add_category: { title: 'Allow add category', allow: 'Allow', notAllow: 'Not allow' },
            value_type: { title: 'Value type', percent: 'Percent', fixed: 'Fixed' },
            recurring_type: {
                title: 'Recurring type',
                life: 'Life',
                year: 'Year',
                month: 'Month',
                day: 'Day',
                week: 'Week',
                hour: 'Hour',
                quarter: 'Quarter',
                minute: 'Minute',
            },
            title: 'Title',
            short_title: 'Short title',
            code: 'Code',
            from: 'From',
            to: 'To',
            description: 'Description',
            image: 'Image',
            image_detail: 'Image detail',
            category: 'Category',
            min_value: 'Min value',
            max_value: 'Max value',
            limit: 'Limit',
            create: 'Create',
            save: 'Save',
            create_success: 'Create event successfully.',
            update_success: 'Update event successfully.',
        },
        event_page_detail: {
            title: 'Title',
            overView: 'Overview',
            maxValue: 'Max value',
            minValue: 'Min value',
            value: 'Value',
            detail: 'About Event',
            typeAndStatus: 'Type and Status',
            startDate: 'Start Date',
            endDate: 'End Date',
            valueType: 'Value Type',
            code: 'Code',
            assign: 'Assign To',
            event_categories: 'Event categories',
        },
        events_banner_management: {
            title: 'dd',
        },
        user_page: {
            information: 'Information',
            name: 'Name',
            avatar: 'Avatar',
            phone_number: 'Phone number',
            address: 'Address',
            birthday: 'Birthday',
            nothingDataUser: 'There is no user here',
            nothingDataWorker: 'There is no worker here',
            index: 'Index',
            role: 'Role',
            province: 'Province',
            sessionRole: 'Role',
            nothingProvince: 'Not Implemented yet',
            provincePlaceholder: 'Province',
            createdAt: 'Created At',
            typeUsers: {
                all: 'All type sorting',
                updatedAt: 'Updated At',
                createdAt: 'Created At',
                allCategory: 'All category',
                allProvince: 'All province',
                worker: 'Worker',
                customer: 'Customer',
            },
            category: 'Categories',
            type: 'Type',
            userType: 'User Type',
            allUserType: 'All User Type',
        },
        notice_page: {
            draft: 'Draft',
            send: 'Send',
            title: 'Title',
            nothingDataNotification: 'There is no notification here',
            recipientNotification: 'Notification Recipients',
            recipientNotificationNumber: 'Number of Recipients',
            actorNotification: 'Actor',
            contentNotification: 'Content Notification',
            issueLinkNotification: 'Issue Link',
            startAt: 'Start At',
        },
        common: {
            events_once_time_management: "Quản lý sự kiện một lần",
            events_banner_management: 'Banner events management',
            issues_management: 'Issues Management',
            events_management: 'Events Management',
            users_management: 'Users Management',
            workers_management: 'Workers Management',
            notice_management: 'Notice Management',
            list: 'List',
            add: 'Create',
            edit: 'Edit',
            search: 'Search',
            first_page: 'First',
            last_page: 'Last',
            previous_page: 'Previous',
            next_page: 'Next',
            logout: 'Logout',
            activated: 'Activated',
            unActivated: 'UnActivated',
            wrongSelectedDate: 'Please select a date to be more than 60 minutes ! after current date !',
            agree: 'Agree',
            cancel: 'Cancelled',
            deleteConfirmation: 'Do you want to delete this ?',
            nothing: 'Nothing',
            noData: 'No data',
            withdrawals: 'Yêu cầu thanh toán',
            error: 'Có lỗi xảy ra, vui lòng thử lại.',
            sales_management: 'Sales',
            info: 'Thong tin',
            skills: 'Skills',
        },
        withdrawals: {
            index: 'STT',
            name: 'Tên người yêu cầu',
            amount: 'Số tiền',
            paymentMethod: 'Hình thức',
            createdAt: 'Ngày tạo',
            status: 'Trạng thái',
            user: 'Tài khoản yêu cầu',
            detail: 'Chi tiết',
            action: 'Action',
            confirmRequest: 'Xác nhận thanh toán',
            rejectRequest: 'Từ chối thanh toán',
            confirm: 'Lưu',
            cancel: 'Cancel',
            description: 'Mô tả',
            image: 'Hình ảnh',
            failReason: 'Nguyên nhân từ chối',
            close: 'Close',
            confirmSuccess: 'Xác nhận thanh toán thành công',
            rejectSuccess: 'Từ chối thanh toán thành công',
        },
        skill: {
            search: 'Search',
            placeholderSearch: 'Worker name / phone',
            infoWorker: 'Info worker',
            category: 'category',
            status: 'Status',
            name: 'Name',
            phone: 'Phone',
            experience: 'Experience',
            reason: 'Reason',
            createdAt: 'Created at',
            detail: 'Detail',
            attachments: 'Attachments',
            approve: 'Approve',
            reject: 'Reject',
            confirm: 'Confirm',
            cancel: 'Cancel',
            reasonRequired: 'Reason is required',
            approveSuccess: 'Approve successfully',
            rejectSuccess: 'Reject successfully',
        },
    },
};

export default En;
