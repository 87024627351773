const CODE_ERROR_CONSTANTS = {
    LOG_0001: "LOG-0001",
    LOG_0002: "LOG-0002",
    USER_0001: "USER-0001",
    USER_0002: "USER-0002",
    USER_0003: "USER-0003",
    ERR_0400: "ERR-0400",
    ERR_0401: "ERR-0401",
    ERR_0403: "ERR-0403",
    ERR_0404: "ERR-0404",
    ERR_0500: "ERR-0500",
    ERR_0422: "ERR-0422",
    IMG_REQUIRED: "IMG_REQUIRED",
    IMG_VALID: "IMG_VALID",
    EVSL_0001: "EVSL-0001",
    EVSL_0002: "EVSL-0002",
    EVSL_0003: "EVSL-0003",
    ADNT_0001: "ADNT-0001",
    ADNT_0002: "ADNT-0002",
}

export { CODE_ERROR_CONSTANTS }


