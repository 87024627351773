import { EDIT_FORM_SUCCESS } from '../../../components/CommonForm/store/constants';
import {
    CLEAR_DATA_USER,
    DELETE_USER_SUCCESS,
    GET_CATEGORIES_SUCCESS,
    GET_PROVINCES_SUCCESS,
    GET_USERS_SUCCESS,
    GET_USER_SUCCESS,
    LIMIT_IN_PAGE_USERS,
    PROCESSING,
    SET_FILTER,
    SET_FILTER_USER_TABLE,
} from './constants';

const initState = {
    users: [],
    user: {},
    processing: false,
    filterUser: {
        page: 1,
        limit: LIMIT_IN_PAGE_USERS,
        from: '',
        to: '',
        sort: ''
    },
    filterUserTable: {
        categoryValue: '',
        provinceValue: '',
    },
    total: 0,
    categories: [],
    provinces: [],
    allUsersResponse: []
};

const AppMenu = (state = initState, action) => {
    switch (action.type) {
        case PROCESSING: {
            return {
                ...state,
                processing: true,
            };
        }
        case GET_USERS_SUCCESS: {
            const {
                data,
                meta: { total },
                allUsersResponse
            } = action.payload;
            return {
                ...state,
                users: data,
                total,
                processing: false,
                allUsersResponse
            };
        }
        case DELETE_USER_SUCCESS: {
            const newUsers = [...state.users].filter((user) => user._id !== action.payload);
            return {
                ...state,
                users: newUsers,
            };
        }

        case GET_USER_SUCCESS: {
            return {
                ...state,
                user: action.payload,
                processing: false,
            };
        }

        case CLEAR_DATA_USER: {
            return {
                ...state,
                user: {},
            };
        }

        case SET_FILTER: {
            return {
                ...state,
                filterUser: action.payload,
            };
        }

        case SET_FILTER_USER_TABLE: {
            return {
                ...state,
                filterUserTable: action.payload,
            };
        }

        case EDIT_FORM_SUCCESS: {
            const { id, values } = action.payload;
            const { name, email, role } = values;
            const newUsers = [...state.users].map((user) => {
                if (user._id === id) {
                    return {
                        ...user,
                        _id: id,
                        username: name,
                        email,
                        role,
                    };
                }
                return user;
            });
            return {
                ...state,
                users: newUsers,
            };
        }

        case GET_CATEGORIES_SUCCESS: {
            return {
                ...state,
                categories: action.payload,
            };
        }

        case GET_PROVINCES_SUCCESS: {
            return {
                ...state,
                provinces: action.payload,
            };
        }

        default:
            return state;
    }
};

export default AppMenu;
