import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as types from './store/constants';
import userAPI from '../../api/usersApi';
import { queryFormat, toastSuccess, formatISODate, expiredToken } from '../../ultils/common';
import { DATE_TYPES, STATUS_CODE } from 'constants/commonConstants';
import {
    getWorkerFailed,
    getWorkerRequest,
    getWorkersFailed,
    getWorkersSuccess,
    getWorkerSuccess,
    processing,
    updateWorkerFailed,
} from './store/actions';

function* getWorkersList(action) {
    const { filterWorker, accessToken } = action.payload;
    try {
        yield put(processing());
        const query = queryFormat(filterWorker);
        const {
            data: { data, meta },
        } = yield call(userAPI.getUsers, query, accessToken);
        yield put(getWorkersSuccess({ data, meta }));
    } catch (error) {
        yield put(getWorkersFailed(error));
        expiredToken(error);
    }
}

function* getWorker(action) {
    const { userId, accessToken } = action.payload;
    try {
        const { data } = yield call(userAPI.getUser, userId, accessToken);
        yield put(getWorkerSuccess(data));
    } catch (error) {
        yield put(getWorkerFailed());
    }
}

function* updateWorker(action) {
    const { values, id, accessToken } = action.payload;
    try {
        const { address, name, birthday, gender, phone_number, year_experience, reliability, is_verified } = values;
        const data = {
            address: address || '',
            name,
            birthday: formatISODate(birthday, DATE_TYPES.FORMAT_DATE),
            gender: gender,
            phone_number,
            year_experience: year_experience,
            reliability,
            status: types.VERIFY_WORKER_TYPES_OBJECT_REQUEST[is_verified]?.id,
        };
        const response = yield call(userAPI.updateUser, id, data, accessToken);
        const { status } = response;
        if (status === STATUS_CODE.UPDATE_SUCCESS) {
            yield put(getWorkerRequest({ userId: id, accessToken }));
            toastSuccess('Updated success');
        }
    } catch (error) {
        yield put(updateWorkerFailed(error));
    }
}

export default function* workersManagementSaga() {
    yield all([
        takeLatest(types.GET_WORKERS_REQUEST, getWorkersList),
        takeLatest(types.GET_WORKER_REQUEST, getWorker),
        takeLatest(types.UPDATE_WORKER_REQUEST, updateWorker),
    ]);
}
