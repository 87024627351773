export const eventRoutes = {
    DETAIL_EVENT: '/events/detail/{eventId}',
    LIST_EVENT: '/events/list',
};

export const issuesRoutes = {
    DETAIL_ISSUE: '/issues/detail/{issueId}',
    LIST_ISSUE: '/issues/list',
};

export const userRoutes = {
    DETAIL_USER: '/user/{userId}',
};

export const notificationsRoutes = {
    DETAIL_NOTIFICATION: '/notification/detail/{notificationId}',
    LIST_NOTIFICATION: '/notifications/list',
    CREATE_NOTIFICATION: '/notification/create',
};
