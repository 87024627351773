/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const accessToken = localStorage.getItem('accessToken');

    return !!(refreshToken && accessToken);
};

export { isUserAuthenticated };
