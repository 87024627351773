import { toastError } from 'ultils/common';
import { CODE_ERROR_CONSTANTS } from './statusCodeConstants';
const handleError = (action) => {
    const { payload } = action;
    // eslint-disable-next-line no-unused-vars
    const status = payload?.response?.data?.status;
    const code = payload?.response?.data?.code;

    switch (code) {
        case CODE_ERROR_CONSTANTS.LOG_0001:
            toastError(`Số điện thoại hoặc mật khẩu không đúng!`);
            break;
        case CODE_ERROR_CONSTANTS.LOG_0002:
            toastError(`Tài khoản chưa được kiểm chứng!`);
            break;
        case CODE_ERROR_CONSTANTS.USER_0001:
            toastError(`Nguời dùng chưa được kích hoạt!`);
            break;
        case CODE_ERROR_CONSTANTS.USER_0003:
            toastError(`Nguời dùng chưa được kiểm chứng!`);
            break;
        case CODE_ERROR_CONSTANTS.USER_0002:
            toastError(`Không tìm thấy người dùng trên!`);
            break;
        case CODE_ERROR_CONSTANTS.ERR_0401:
            localStorage.clear();
            window.location.replace('/account/login');
            break;
        case CODE_ERROR_CONSTANTS.ERR_0400:
            toastError(`Yêu cầu không hợp lệ!`);
            break;
        case CODE_ERROR_CONSTANTS.ERR_0403:
            toastError(`Yêu cầu bị từ chối!`);
            break;
        case CODE_ERROR_CONSTANTS.ERR_0404:
            window.location.replace('/pageNotFound');
            break;
        case CODE_ERROR_CONSTANTS.ERR_0422:
            toastError(`Dữ liệu không hợp lệ, vui kiểm tra lại!`);
            break;
        case CODE_ERROR_CONSTANTS.ERR_0500:
            toastError(`Có lỗi ở máy chủ, vui lòng thử lại sau!`);
            break;
        case CODE_ERROR_CONSTANTS.IMG_REQUIRED:
            toastError(`Hình ảnh không được bỏ trống!`);
            break;
        case CODE_ERROR_CONSTANTS.IMG_VALID:
            toastError(`Hình ảnh không đúng định dạng!`);
            break;
        case CODE_ERROR_CONSTANTS.EVSL_0001:
            toastError(`Sự kiện này không tìm thấy!`);
            break;
        case CODE_ERROR_CONSTANTS.EVSL_0002:
            toastError(`Sự kiện không hợp lệ!`);
            break;
        case CODE_ERROR_CONSTANTS.EVSL_0003:
            toastError(`Sự kiện này không phù hợp với vấn đề của bạn!`);
            break;
        case CODE_ERROR_CONSTANTS.ADNT_0001:
            toastError(`Không tìm thấy người quản lý thông báo!`);
            break;
        case CODE_ERROR_CONSTANTS.ADNT_0002:
            toastError(`Không được phép cập nhật thông báo người dùng này !`);
            break;
        default:
            break;
    }
};

export const throwMiddleware = () => (next) => (action) => {
    if (action.type.indexOf('_FAILED') > 0) {
        handleError(action);
    }
    next(action);
};