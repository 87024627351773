import { OPEN_MODAL, CLOSE_MODAL } from './constants';

const initState = {
    isOpen: false,
    label: '',
    isForm: false,
    handleSubmit: () => {},
};

const ModalReducer = (state = initState, action) => {
    switch (action.type) {
        case OPEN_MODAL: {
            return {
                ...state,
                ...action.payload,
            };
        }

        case CLOSE_MODAL: {
            return {
                ...state,
                isOpen: false,
            };
        }

        default:
            return { ...state };
    }
};

export default ModalReducer;
