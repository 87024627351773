export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILED = 'GET_NOTIFICATION_FAILED';
export const SET_FILTER_NOTIFICATION = 'SET_FILTER_NOTIFICATION';
export const SET_FILTER_DEFAULT = 'SET_FILTER_DEFAULT';
export const SET_IS_EDIT_NOTIFICATION = 'SET_IS_EDIT_NOTIFICATION';
export const SET_FILTER_DEBOUNCE = 'SET_FILTER_DEBOUNCE';
export const PROCESSING = 'PROCESSING';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const SET_NOTIFICATIONS_DEFAULT = 'SET_NOTIFICATIONS_DEFAULT';
export const SET_ON_EDIT = 'SET_ON_EDIT';
export const ADD_NEW_NOTIFICATION = 'ADD_NEW_NOTIFICATION';
export const ADD_NEW_NOTIFICATION_FAILED = 'ADD_NEW_NOTIFICATION_FAILED';
export const EDIT_NOTIFICATION = 'EDIT_NOTIFICATION';
export const EDIT_NOTIFICATION_FAILED = 'EDIT_NOTIFICATION_FAILED';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILED = 'DELETE_NOTIFICATION_FAILED';
