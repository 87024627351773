// @flow
import {
    CLEAR_ERROR,
    FORGET_PASSWORD,
    FORGET_PASSWORD_FAILED,
    FORGET_PASSWORD_SUCCESS,
    LOGIN_USER,
    LOGIN_USER_FAILED,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REFRESH_PAGE_FAILED,
    REFRESH_PAGE_SUCCESS,
    REGISTER_USER,
    REGISTER_USER_FAILED,
    REGISTER_USER_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_SUCCESS,
} from './constants';

const INIT_STATE = {
    user: {},
    accessToken: '',
    loading: false,
};

const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            const { accessToken, user } = action.payload;
            return { ...state, user, accessToken, loading: false, error: null };
        case LOGIN_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, loading: false, error: null };
        case REGISTER_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
            return { ...state, user: null };
        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, loading: false, error: null };
        case FORGET_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case RESET_PASSWORD:
            return { ...state, loading: true };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false, error: null };
        case RESET_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REFRESH_PAGE_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                accessToken: action.payload.accessToken,
                loading: false,
                error: null,
            };
        case REFRESH_PAGE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CLEAR_ERROR:
            return { ...state, error: null, loading: false };
        default:
            return { ...state };
    }
};

export default Auth;
