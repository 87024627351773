/* eslint-disable no-unused-vars */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated } from '../helpers/authUtils';
import HomePage from 'modules/home/index';

// sales
const SaleList = React.lazy(() => import('../modules/sale/containers/List'));
const SaleUserDetail = React.lazy(() => import('../modules/sale-qr/containers/SaleUserDetail'));
const SaleDetail = React.lazy(() => import('../modules/sale/containers/SaleDetail'));
const YourSales = React.lazy(() => import('../modules/sale/containers/YourSales'));
const QrList = React.lazy(() => import('../modules/sale-qr/containers/QrList'));
const QrDetail = React.lazy(() => import('../modules/sale-qr/containers/QrDetail'));
const QrListBySale = React.lazy(() => import('../modules/sale/containers/QrListBySale'));

// auth
const Login = React.lazy(() => import('../modules/auth/Login'));
// const Logout = React.lazy(() => import('../modules/auth/Logout'));
const Register = React.lazy(() => import('../modules/auth/Register'));
const ForgetPassword = React.lazy(() => import('../modules/auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../modules/auth/ResetPassword'));
const Confirm = React.lazy(() => import('../modules/auth/Confirm'));

// issues
const IssueList = React.lazy(() => import('../modules/issues/containers/List'));
const IssueDetail = React.lazy(() => import('../modules/issues/containers/Detail'));

// event
const EventList = React.lazy(() => import('../modules/events/containers/List'));
const EventDetail = React.lazy(() => import('../modules/events/Detail'));
const CreateEventPage = React.lazy(() => import('../modules/events/containers/CreateEventPage'));
const EditEventPage = React.lazy(() => import('../modules/events/containers/EditEventPage'));

// banner
const BannerEventsList = React.lazy(() => import('../modules/events-banner/containers/List'));
const DetailBannerEventPage = React.lazy(() => import('../modules/events-banner/containers/Detail'));
const CreateBannerEventPage = React.lazy(() => import('../modules/events-banner/containers/CreateBannerEvent'));

// event
const EventOnceTimeList = React.lazy(() => import('../modules/events-once-time/containers/List'));
const CreateEventOnceTime = React.lazy(() => import('../modules/events-once-time/containers/Create'));
const DetailEventOnceTime = React.lazy(() => import('../modules/events-once-time/containers/Detail'));

// users
const UserList = React.lazy(() => import('../modules/users/containers/List.jsx'));
const UserDetail = React.lazy(() => import('../modules/users/containers/Detail.jsx'));

// workers
const WorkerList = React.lazy(() => import('../modules/workers/containers/List.jsx'));
const WorkerDetail = React.lazy(() => import('../modules/workers/containers/Detail.jsx'));

// Notifications
const CreateNotificationPage = React.lazy(() => import('../modules/notifications/containers/CreateNotificationPage'));
const NotificationDetail = React.lazy(() => import('../modules/notifications/containers/NotificationDetail'));
const NotificationList = React.lazy(() => import('../modules/notifications/containers/NotificationList'));

/** NotFound Page */
const PageNotFound = React.lazy(() => import('../components/PageNotFound/PageNotFound'));

/** WithDrawals Page */
const WithDrawalsList = React.lazy(() => import('../modules/request-withdraw/containers/RequestWithdrawalsList'));
const WithDrawalsDetail = React.lazy(() => import('../modules/request-withdraw/containers/RequestWithDrawalsDetail'));

/** Skills Page */
const ListSkillPage = React.lazy(() => import('modules/skills/containers/ListSkillPage'));
const DetailSkillPage = React.lazy(() => import('modules/skills/containers/DetailSkillPage'));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, currentRole, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            if (roles && !roles.includes(`${currentRole[0].toUpperCase()}${currentRole.slice(1).toLowerCase()}`)) {
                return <Redirect to={{ pathname: '/pageNotFound', state: { from: props.location } }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: HomePage,
    route: PrivateRoute,
};

const issuesAppRoutes = {
    path: '/issues/list',
    name: 'Issues',
    icon: FeatherIcon.List,
    header: 'issues_management',
    roles: ['Admin', 'Consulting'],
    children: [
        {
            path: '/issues/list',
            name: 'List',
            component: IssueList,
            route: PrivateRoute,
            roles: ['Admin', 'Accountant', 'Consulting'],
        },
        {
            path: '/issues/detail/:idIssue',
            component: IssueDetail,
            route: PrivateRoute,
            roles: ['Admin', 'Accountant', 'Consulting'],
        },
    ],
};

const eventsAppRoutes = {
    path: '/events/list',
    name: 'Events',
    icon: FeatherIcon.Tv,
    header: 'events_management',
    roles: ['Admin'],
    children: [
        {
            path: '/events/list',
            name: 'List',
            component: EventList,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/events/detail/:idEvent',
            component: EventDetail,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/events/createEventPage',
            component: CreateEventPage,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/events/edit/:idEvent',
            component: EditEventPage,
            route: PrivateRoute,
            roles: ['Admin'],
        },
    ],
};

const eventsOnceTimeRoutes = {
    path: '/events-once-time/list',
    name: 'Events',
    icon: FeatherIcon.Tv,
    header: 'events_once_time_management',
    roles: ['Admin'],
    children: [
        {
            path: '/events-once-time/list',
            name: 'List',
            component: EventOnceTimeList,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/events-once-time/create',
            component: CreateEventOnceTime,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/events-once-time/detail/:idPrefixCode',
            component: DetailEventOnceTime,
            route: PrivateRoute,
            roles: ['Admin'],
        },
    ],
};

const eventsBannerRoutes = {
    path: '/events-banner/list',
    name: 'Events',
    icon: FeatherIcon.Image,
    header: 'events_banner_management',
    roles: ['Admin'],
    children: [
        {
            path: '/events-banner/list',
            name: 'List',
            component: BannerEventsList,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/events-banner/detail/:idEventBanner',
            component: DetailBannerEventPage,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/events-banner/create',
            component: CreateBannerEventPage,
            route: PrivateRoute,
            roles: ['Admin'],
        },
    ],
};

const usersManagementRoutes = {
    path: '/users/list',
    name: 'Users',
    header: 'users_management',
    icon: FeatherIcon.Users,
    roles: ['Admin'],
    children: [
        {
            path: '/users/list',
            name: 'List',
            component: UserList,
            route: PrivateRoute,
        },
        {
            path: `/users/:id`,
            component: UserDetail,
            route: PrivateRoute,
        },
    ],
};

const workersManagementRoutes = {
    path: '/workers/list',
    name: 'Workers',
    header: 'workers_management',
    icon: FeatherIcon.Tool,
    roles: ['Admin', 'Consulting'],
    children: [
        {
            path: '/workers/list',
            name: 'List',
            component: WorkerList,
            route: PrivateRoute,
        },
        {
            path: `/workers/:id`,
            component: WorkerDetail,
            route: PrivateRoute,
        },
    ],
};

const notificationManagementRoutes = {
    path: '/notifications',
    name: 'Notifications',
    header: 'notice_management',
    icon: FeatherIcon.Bell,
    roles: ['Admin'],
    children: [
        {
            path: '/notifications',
            name: 'Notification List',
            component: NotificationList,
            route: PrivateRoute,
        },
        {
            path: '/notification/create',
            name: 'Create Notification',
            component: CreateNotificationPage,
            route: PrivateRoute,
            exact: true,
        },
        {
            path: '/notification/create/:idNotification',
            name: 'Create Notification',
            component: CreateNotificationPage,
            route: PrivateRoute,
            exact: true,
        },
        {
            path: '/notification/detail/:idNotification',
            name: 'Notification Detail',
            component: NotificationDetail,
            route: PrivateRoute,
            exact: true,
        },
    ],
};

const withdrawalsRoutes = {
    path: '/withdrawals',
    name: 'Withdrawals',
    header: 'withdrawals',
    icon: FeatherIcon.DollarSign,
    roles: ['Admin', 'Accountant'],
    children: [
        {
            path: '/withdrawals',
            name: 'Withdrawals',
            component: WithDrawalsList,
            route: PrivateRoute,
            roles: ['Admin', 'Accountant'],
            exact: true,
        },
        {
            path: '/withdrawals/detail/:id',
            name: 'Withdrawals detail',
            component: WithDrawalsDetail,
            route: PrivateRoute,
            roles: ['Accountant'],
        },
    ],
};

const salesManagementRoutes = {
    path: '/sales/list',
    name: 'Sales',
    header: 'sales_management',
    icon: FeatherIcon.Users,
    roles: ['Admin'],
    children: [
        {
            path: '/sales/list',
            name: 'Sale list',
            component: SaleList,
            route: PrivateRoute,
            roles: ['Admin'],
            exact: true,
        },
        {
            path: `/sales/:id`,
            name: 'Sale detail',
            component: QrListBySale,
            route: PrivateRoute,
            roles: ['Admin'],
            exact: true,
        },
        {
            path: `/sales/:idSale/:id/`,
            name: 'Sale detail',
            component: QrDetail,
            route: PrivateRoute,
            roles: ['Admin'],
            exact: true,
        },
    ],
};

const salesKpiRoutes = {
    path: '/sale-qr',
    name: 'Sales',
    header: 'sales_management',
    icon: FeatherIcon.Users,
    roles: ['Sale'],
    children: [
        {
            path: `/sale-qr`,
            name: 'Sale qr',
            component: QrList,
            route: PrivateRoute,
            roles: ['Sale'],
            exact: true,
        },
        {
            path: `/sale-qr/:id`,
            name: 'Sale qr detail',
            component: QrDetail,
            route: PrivateRoute,
            roles: ['Sale'],
            exact: true,
        },
    ],
};
const salesInfoKpiRoutes = {
    path: '/info',
    name: 'Thong tin',
    header: 'info',
    icon: FeatherIcon.BarChart2,
    roles: ['Sale'],
    children: [
        {
            path: `/info`,
            name: 'Thong tin',
            component: SaleUserDetail,
            route: PrivateRoute,
            roles: ['Sale'],
            exact: true,
        },
    ],
};

const skillsKpiRoutes = {
    path: '/skills',
    name: 'Skills',
    header: 'skills',
    icon: FeatherIcon.BarChart2,
    roles: ['Admin', 'Consulting'],
    children: [
        {
            path: `/skills`,
            name: 'Skills',
            component: ListSkillPage,
            route: PrivateRoute,
            roles: ['Admin', 'Consulting'],
            exact: true,
        },
        {
            path: `/skills/:id`,
            name: 'Skill detail',
            component: DetailSkillPage,
            route: PrivateRoute,
            roles: ['Admin', 'Consulting'],
            exact: true,
        },
    ],
};

const appRoutes = [
    issuesAppRoutes,
    skillsKpiRoutes,
    eventsOnceTimeRoutes,
    eventsAppRoutes,
    eventsBannerRoutes,
    salesManagementRoutes,
    salesInfoKpiRoutes,
    salesKpiRoutes,
    usersManagementRoutes,
    workersManagementRoutes,
    notificationManagementRoutes,
    withdrawalsRoutes,
];

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        // {
        //     path: '/account/login',
        //     name: 'Logout',
        //     // component: Logout,
        //     route: Route,
        // },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/reset/:accessToken',
            name: 'Reset Password',
            component: ResetPassword,
            route: Route,
        },
    ],
};

const pageNotFoundRoute = {
    path: '/pageNotFound',
    name: 'Page Not Found',
    children: [
        {
            path: '/pageNotFound',
            name: 'Page Not Found',
            component: PageNotFound,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, ...appRoutes, authRoutes, pageNotFoundRoute];

const authProtectedRoutes = [...appRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
