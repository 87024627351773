import { DATE_TYPES } from 'constants/commonConstants';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { isNil } from 'lodash';
import { LIMIT_IN_PAGE_USERS } from 'modules/users/store/constants';
import moment from 'moment';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import history from './history';

export const toastError = (msg) => {
    return toast.error(`${msg}`, {
        position: 'top-right',
    });
};

export const toastSuccess = (msg) => {
    return toast.success(`${msg}`, {
        position: 'top-right',
    });
};

export const queryFormatObject = (string) => {
    const query = queryString.parse(string);
    return query;
};

export const formatDateFunc = (date) => {
    return date ? moment(date).format(DATE_TYPES.FORMAT_DATE) : '';
};

export const formatDateTimeFunc = (date) => {
    return moment(date).format(DATE_TYPES.FORMAT_DATE_TIME_ZONE);
};

export const formatISODate = (date, format = DATE_TYPES.FORMAT_DATE_IOS) => {
    const newDate = String(date).split('GMT')[0];
    return moment(newDate).format(format);
};

export const dateComparison = (dateBefore, dateAfter) => {
    return moment(dateAfter).isAfter(dateBefore, 'day');
};

export const convertImageUrlToFile = async (url, fileName) => {
    const response = await fetch(url)
        .then(async (response) => {
            const contentType = response.headers.get('content-type');
            const blob = await response.blob();
            const file = new File([blob], fileName, { contentType });
            return file;
        })
        .catch((err) => console.log(err));
    return response;
};

export const checkValidUrl = (url) => {
    // eslint-disable-next-line no-useless-escape
    var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    return regex.test(url);
};

export const formatFieldName = (string) => {
    return string.replace(/_/g, ' ');
};

export const expiredToken = (err) => {
    localStorage.setItem('accessToken', '');
    localStorage.setItem('refreshToken', '');
    history.push('/account/login');
    toastError(err.response?.data.message);
};

export const toObject = (arr, key = 'value') => {
    if (!arr) return {};
    return arr.reduce((result, x) => {
        const isEmpty = [null, undefined, ''].includes(x[key]);
        if (isEmpty) return result;
        result = { ...result, [x[key]]: x };
        return result;
    }, {});
};

export const removeEmptyValuesObject = (object) => {
    Object.keys(object).forEach((key) => {
        if (object[key] === '') {
            delete object[key];
        }
    });

    return object;
};

export const queryFormat = (object) => {
    const query = '?' + queryString.stringify(removeEmptyValuesObject(object));
    return query;
};

export const handleSetSessionStorage = (key, value) => {
    return key && value && sessionStorage.setItem(key, JSON.stringify(value));
};

export const handleGetValueSessionStorage = (key) => {
    return key && JSON.parse(sessionStorage.getItem(key));
};

export const handleSearchInData = (searchText, data, field) => {
    return data[field]?.toLowerCase().includes(searchText?.toLowerCase());
};

export function deepEqual(a, b) {
    if (a === b) {
        return true;
    }
    if (!a || !b) {
        return false;
    }
    if (Array.isArray(a)) {
        if (!Array.isArray(b) || a.length !== b.length) {
            return false;
        }
        for (let i = 0; i < a.length; i++) {
            if (!deepEqual(a[i], b[i])) {
                return false;
            }
        }
        return true;
    } else if (Array.isArray(b)) {
        return false;
    }
    if (typeof a === 'object' && typeof b === 'object') {
        const aKeys = Object.keys(a);
        const bKeys = Object.keys(b);
        if (aKeys.length !== bKeys.length) {
            return false;
        }
        for (const key of aKeys) {
            // eslint-disable-next-line no-prototype-builtins
            if (!b.hasOwnProperty(key)) {
                return false;
            }
            if (!deepEqual(a[key], b[key])) {
                return false;
            }
        }
        return true;
    }
    return false;
}

export const removeDuplicateEl = (arr) =>
    arr.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);

export const calculateIndex = (dataArray, id, page, pageSize = LIMIT_IN_PAGE_USERS) => {
    const indexArray = dataArray.findIndex((data) => data.id === id);
    if (indexArray < 0) return;
    if (page === 1) return indexArray + 1;
    return pageSize * (page - 1) + indexArray + 1;
};

export const convertEditorToHTML = (editorContent) => {
    return draftToHtml(convertToRaw(editorContent.getCurrentContent()));
};

export const convertHtmlToString = (HtmlEditor) => {
    return HtmlEditor.replace(/<[^>]+>/g, '');
};

export const convertToPlain = (html) => {
    //remove \n in html
    const _html = html.split('\n').join('');

    // Create a new div element
    const tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = _html;

    // Retrieve the text property of the element
    const result =
        tempDivElement.textContent.slice(0, tempDivElement.textContent.length - 2) ||
        tempDivElement.innerText.slice(0, tempDivElement.innerText.length - 2) ||
        '';
    return result;
};

export const addTimeBasedOnCurrentDate = (currentDate, numberOfUnit, unit, dateFormat) => {
    return moment(currentDate, dateFormat).add(numberOfUnit, unit).format(dateFormat);
};

export const subtractTimeBasedOnCurrentDate = (currentDate, numberOfUnit, unit, dateFormat) => {
    return moment(currentDate, dateFormat).subtract(numberOfUnit, unit).format(dateFormat);
};

export const convertStringToArray = (string, characterSymbol) => {
    return string ? string.split(characterSymbol) : [];
};

export const getTextBodyDraft = (value) => {
    return convertToRaw(value).blocks[0].text;
};

export const isEmptyObject = (object) => {
    if (!object) return true;
    return Object.entries(object).length === 0 ? true : false;
};

export const formatDateTimeZoneFunc = (date) => {
    return moment.utc(date).format();
};

export const convertHTMLToRenderDraft = (content) => {
    const contentBlocks = convertFromHTML(content);
    const contentState = ContentState?.createFromBlockArray(contentBlocks?.contentBlocks, contentBlocks?.entityMap);
    return EditorState?.createWithContent(contentState);
};

export const getCurrentDate = () => {
    return new Date(new Date(new Date()).setHours(new Date().getHours(), new Date().getMinutes() + 60));
};

export const formatTimeZoneDateString = (date, format = DATE_TYPES.FORMAT_DATE_TIME_ZONE) => {
    return moment.utc(date).local().format(format);
};

const buildFormData = (formData, data, parentKey) => {
    if (Array.isArray(data)) {
        data.forEach((el) => {
            buildFormData(formData, el, parentKey);
        });
    } else if (typeof data === 'object' && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}.${key}` : key);
        });
    } else {
        if (isNil(data)) {
            return;
        }

        const value = typeof data === 'boolean' || typeof data === 'number' ? data.toString() : data;
        formData.append(parentKey, value);
    }
};

export const getFormData = (data) => {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
};

const buildFormDataMapArr = (formData, data, parentKey) => {
    if (Array.isArray(data)) {
        data.forEach((el, index) => {
            buildFormDataMapArr(formData, el, `${parentKey}[${index}]`);
        });
    } else if (typeof data === 'object' && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            buildFormDataMapArr(formData, data[key], parentKey ? `${parentKey}.${key}` : key);
        });
    } else {
        if (isNil(data)) {
            return;
        }

        const value = typeof data === 'boolean' || typeof data === 'number' ? data.toString() : data;
        formData.append(parentKey, value);
    }
};

export const getFormDataMapArr = (data) => {
    const formData = new FormData();
    buildFormDataMapArr(formData, data);
    return formData;
};

export const removeHTMLTags = (rawText) => {
    if (!rawText) return '';
    return br2nl(rawText).replace(/(<([^>]+)>)/gi, '');
};

export const decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};

export const br2nl = (str) => {
    return str.replace(/<br\s*\/?>/gm, '\n');
};

export const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
    });

const readFile = (file) =>
    new Promise()((resolve) => {
        const reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                if (typeof reader.result === 'string') {
                    resolve(reader.result);
                }
            },
            false
        );
        reader.readAsDataURL(file);
    });

export const PAGE_SIZE = [500, 1000, 1500, 2000, 5000];
export const LIMIT_PER_PAGE = PAGE_SIZE[0];

export const LIMIT_DEFAULT_LIST_EVENT = 5000;
