import {
    CLEAR_ISSUE,
    GET_ISSUE,
    GET_ISSUES_FAILED,
    GET_ISSUES_SUCCESS,
    GET_ISSUE_FAILED,
    GET_ISSUE_SUCCESS,
    PROCESSING,
    SET_ISSUES_DEFAULT,
} from './constants';

const INIT_STATE = {
    issues: [],
    issue: {},
    loading: false,
    total: 0,
};

const Issues = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PROCESSING:
            return { ...state, loading: true };
        case GET_ISSUES_SUCCESS:
            const { data, meta } = action.payload;
            return { ...state, issues: data, loading: false, total: meta.total };
        case GET_ISSUES_FAILED:
            return { ...state, loading: false };
        case SET_ISSUES_DEFAULT:
            return { ...state, issues: [], loading: false, error: null };

        case GET_ISSUE:
            return { ...state, loading: true };
        case GET_ISSUE_SUCCESS:
            return { ...state, issue: action.payload, loading: false, error: null };
        case GET_ISSUE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case CLEAR_ISSUE:
            return { ...state, issue: {} };
        default:
            return state;
    }
};

export default Issues;
