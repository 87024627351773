import axiosClient from './axiosClient';

const UserAPI = {
    getUsers: (data, token) => {
        const url = `users${data}`;
        return axiosClient.get(url, token);
    },
    getUser: (data, token) => {
        const url = `users/${data}`;
        return axiosClient.get(url, token);
    },
    updateUser: (id, data, token) => {
        const url = `users/${id}`;
        return axiosClient.patch(url, data, token);
    },
};

export default UserAPI;
