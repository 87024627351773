import axiosClient from './axiosClient';

const AuthAPI = {
    postSignIn: (data) => {
        const url = `login`;
        return axiosClient.post(url, data);
    },
    postRefreshToken: (data, token) => {
        const url = `refresh-token`;
        return axiosClient.post(url, data, token);
    },
    postLogout: () => {
        const url = 'logout';
        return axiosClient.post(url);
    },
};

export default AuthAPI;
